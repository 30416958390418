import { useState, useMemo, useEffect } from "react";
import { useSwitcherClientCore } from "hooks/useSwitcherClient";
import { Tabs } from "components/tabs/Tabs";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { BroadcastResponse } from "@switcherstudio/api-core-client";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import {
    ActionsBarOptions,
    ComponentItem
} from "components/generic-multiselect/types";
import { useAddVideosActions } from "./useAddVideoActions";
import { useGenericMultiSelect } from "components/generic-multiselect/useGenericMultiSelect";
import { AddVideosContent } from "./AddVideosContent";
import { v4 as uuidv4 } from "uuid";
import {
    BroadcastResponseDetails,
    BroadcastResponseDetailsProps
} from "components/entity-details/BroadcastResponseDetails";
import { Spinner } from "components/spinners/Spinner";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useUserStatus } from "hooks/useUserStatus";
import { Link } from "components/buttons/Link";

export enum VideoFilters {
    VideoLibrary = "video-library",
    Scheduled = "scheduled"
}
export const AddContent = ({
    setIsOpen,
    onSelect,
    previouslySelectedIds
}: any) => {
    const [uploadSessionId, setUploadSessionId] = useState<string>(uuidv4());
    const [uploadModalKey, setUploadModalKey] = useState<number>(0);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [allBroadcasts, setAllBroadcasts] = useState<BroadcastResponse[]>([]);
    const { videoPlayerId } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { userHasNoSubscription } = useUserStatus();

    // Redux state
    const { userInfo } = useSelector((state: RootState) => state.user);
    const projectId = userInfo?.ProjectId;

    // Fetch data
    const {
        dispatchApiRequest: refetchVideos,
        loading,
        error
    } = useSwitcherClientCore(
        (client) => client.broadcasts_GetPagedBroadcastsByFilter,
        {
            args: [projectId, 1, 0, "Project"],
            requestImmediately: true,
            preFetch: () => {
                setUploadModalKey((prev) => prev + 1);
            },
            onSuccess: (res) => {
                setIsFirstLoad(false);
                setAllBroadcasts(res.Data[0].Broadcasts);
            }
        }
    );

    // Local state
    const { firstLoading, reloading } = useMemo(
        () => ({
            firstLoading: loading && isFirstLoad,
            reloading: loading && !isFirstLoad
        }),
        [loading, isFirstLoad]
    );

    const [activeTab, setActiveTab] = useState<VideoFilters>(
        VideoFilters.VideoLibrary
    );

    const { existingBroadcasts, scheduledBroadcasts } = useMemo((): {
        [key: string]: BroadcastResponse[];
    } => {
        if (!allBroadcasts || !allBroadcasts.length) {
            return {
                existingBroadcasts: [],
                scheduledBroadcasts: []
            };
        }

        const existingBroadcasts = [];
        const scheduledBroadcasts = [];

        for (const broadcast of allBroadcasts) {
            const hasEndTime = broadcast.Details.BroadcastStatus === "Ended";
            const hasStarted = broadcast.Details.StartsAt <= new Date();

            const isScheduledLivestream =
                broadcast.Details.BroadcastStatus === "Ready";
            const isScheduledPremiere = !hasStarted && hasEndTime;
            const isExistingContent = hasStarted && hasEndTime;

            if (isScheduledLivestream) {
                scheduledBroadcasts.push(broadcast);
            } else if (isExistingContent || isScheduledPremiere) {
                existingBroadcasts.push(broadcast);
            }
        }

        return {
            existingBroadcasts,
            scheduledBroadcasts
        };
    }, [allBroadcasts]);

    const getBroadcastEntitlements = (broadcast: BroadcastResponse) => {
        const broadcastCollection = broadcast?.Collections?.[0];

        if (!broadcastCollection) return [];

        const collectionEntitlements = broadcastCollection?.Entitlements ?? [];

        const broadcastEntitlements =
            broadcastCollection.CollectionVideos?.find(
                (cv) => cv.Broadcast?.Details?.Id === broadcast.Details.Id
            )?.Entitlements ?? [];

        return [...collectionEntitlements, ...broadcastEntitlements];
    };

    /// EXISTING
    const {
        handleDelete: existingHandleDelete,
        actionsBarOptions: existingActionBarOptions,
        searchSortOptions: existingSearchSortOptions
    } = useAddVideosActions({
        refetchVideos,
        isVideoLibrary: false
    });

    const existingBroadcastListItemComponentItems: ComponentItem<BroadcastResponse>[] =
        useMemo(() => {
            if (!existingBroadcasts.length) return [];

            return existingBroadcasts.map((broadcast) => {
                const entitlements = getBroadcastEntitlements(broadcast);

                return {
                    id: broadcast.Details.Id,
                    component: (
                        <BroadcastResponseDetails
                            broadcast={broadcast}
                            badges
                            entitlements={entitlements}
                            showPricing
                            showEdit={false}
                            showDownload
                            allowVideoPlaybackOnThumbnailClick
                            location="video-library"
                            handleDeleteBroadcast={() => {
                                existingHandleDelete(broadcast, entitlements);
                            }}
                            onBroadcastUpdate={() => {
                                refetchVideos();
                            }}
                            disabled={broadcast.Videos?.some((v) => !v)}
                        />
                    ),
                    baseObject: broadcast
                };
            });
        }, [existingBroadcasts, refetchVideos, existingHandleDelete]);

    const {
        GenericMultiSelectComponent: ExistingGenericMultiSelectComponent,
        handleSelectOrDeselectAll: existingHandleSelectOrDeselectAll,
        handleSubmit: existingHandleSubmit,
        anyNewSelected: existingAnyNewSelected,
        visibleItems: existingVisibleItems,
        anyVisibleSelected: existingAnyVisibleSelected,
        selectedIdSet: existingSelectedIdSet,
        passthroughError: existingPassthroughError,
        setPassthroughError: setExistingPassthroughError
    } = useGenericMultiSelect<BroadcastResponse, BroadcastResponseDetailsProps>(
        {
            isMultiple: true,
            checkBoxLocation: "right",
            allowItemClick: true,
            items: existingBroadcastListItemComponentItems,
            onSubmit: (ids) => {
                if (onSelect && setIsOpen) {
                    onSelect(
                        ids.filter((id) => !previouslySelectedIds.includes(id))
                    );
                    setIsOpen(false);
                }
            },
            previouslySelectedIds,
            emptyStateCopy: t("video-library:no-videos-match"),
            actionsBarOptions:
                existingActionBarOptions as ActionsBarOptions<BroadcastResponseDetailsProps>,
            searchSortOptions: existingSearchSortOptions,
            loading: firstLoading,
            supressNotifications: true
        }
    );

    const ExistingAddVideosContentComponent = useMemo(
        () => (
            <AddVideosContent
                isVideoLibrary={false}
                hasVideos={existingBroadcasts.length !== 0}
                refetchVideos={refetchVideos}
                firstLoading={firstLoading}
                reloading={reloading}
                GenericMultiSelectComponent={
                    ExistingGenericMultiSelectComponent
                }
                t={t}
                userHasNoSubscription={userHasNoSubscription}
                videoUploadSessionId={uploadSessionId}
                setVideoUploadSessionId={setUploadSessionId}
                playerId={videoPlayerId}
                uploadModalKey={uploadModalKey}
            />
        ),
        [
            existingBroadcasts,
            refetchVideos,
            firstLoading,
            reloading,
            ExistingGenericMultiSelectComponent,
            t,
            userHasNoSubscription,
            uploadSessionId,
            setUploadSessionId,
            videoPlayerId,
            uploadModalKey
        ]
    );

    /// SCHEDULED
    const {
        handleDelete: scheduledHandleDelete,
        actionsBarOptions: scheduledActionBarOptions,
        searchSortOptions: scheduledSearchSortOptions
    } = useAddVideosActions({
        refetchVideos,
        isVideoLibrary: false
    });

    const scheduledBroadcastListItemComponentItems: ComponentItem<BroadcastResponse>[] =
        useMemo(() => {
            if (!scheduledBroadcasts.length) return [];

            return scheduledBroadcasts.map((broadcast) => {
                const entitlements = getBroadcastEntitlements(broadcast);
                return {
                    id: broadcast.Details.Id,
                    component: (
                        <BroadcastResponseDetails
                            broadcast={broadcast}
                            badges
                            entitlements={entitlements}
                            showPricing
                            showEdit={false}
                            showDownload
                            allowVideoPlaybackOnThumbnailClick
                            location="video-library"
                            handleDeleteBroadcast={() => {
                                scheduledHandleDelete(broadcast, entitlements);
                            }}
                            onBroadcastUpdate={() => {
                                refetchVideos();
                            }}
                            disabled={broadcast.Videos?.some((v) => !v)}
                        />
                    ),
                    baseObject: broadcast
                };
            });
        }, [scheduledBroadcasts, refetchVideos, scheduledHandleDelete]);

    const {
        GenericMultiSelectComponent: ScheduledGenericMultiSelectComponent,
        handleSelectOrDeselectAll: scheduledHandleSelectOrDeselectAll,
        handleSubmit: scheduledHandleSubmit,
        anyNewSelected: scheduledAnyNewSelected,
        visibleItems: scheduledVisibleItems,
        anyVisibleSelected: scheduledAnyVisibleSelected,
        selectedIdSet: scheduledSelectedIdSet,
        passthroughError: scheduledPassthroughError,
        setPassthroughError: setScheduledPassthroughError
    } = useGenericMultiSelect<BroadcastResponse, BroadcastResponseDetailsProps>(
        {
            isMultiple: true,
            checkBoxLocation: "right",
            allowItemClick: true,
            items: scheduledBroadcastListItemComponentItems,
            onSubmit: (ids) => {
                if (onSelect && setIsOpen) {
                    onSelect(
                        ids.filter((id) => !previouslySelectedIds.includes(id))
                    );
                    setIsOpen(false);
                }
            },
            previouslySelectedIds,
            emptyStateCopy: t("video-library:no-videos-match"),
            actionsBarOptions:
                scheduledActionBarOptions as ActionsBarOptions<BroadcastResponseDetailsProps>,
            searchSortOptions: scheduledSearchSortOptions,
            loading: firstLoading,
            supressNotifications: true
        }
    );

    const globalVisibleItems = useMemo(() => {
        if (firstLoading || !existingVisibleItems || !scheduledVisibleItems) {
            return [];
        }
        return [...existingVisibleItems, ...scheduledVisibleItems];
    }, [existingVisibleItems, scheduledVisibleItems, firstLoading]);

    const globalAnyVisibleSelected = useMemo(
        () => existingAnyVisibleSelected || scheduledAnyVisibleSelected,
        [existingAnyVisibleSelected, scheduledAnyVisibleSelected]
    );

    const globalAnyNewSelected = useMemo(
        () => existingAnyNewSelected || scheduledAnyNewSelected,
        [existingAnyNewSelected, scheduledAnyNewSelected]
    );

    const numberCurrentlySelected = useMemo(() => {
        // Filter out the previously selected ids
        const existingSelectedIds = [...existingSelectedIdSet].filter(
            (id) => !previouslySelectedIds.includes(id)
        );

        const scheduledSelectedIds = [...scheduledSelectedIdSet].filter(
            (id) => !previouslySelectedIds.includes(id)
        );

        return existingSelectedIds.length + scheduledSelectedIds.length;
    }, [existingSelectedIdSet, scheduledSelectedIdSet, previouslySelectedIds]);

    const globalHandleSelectOrDeselectAll = () => {
        existingHandleSelectOrDeselectAll();
        scheduledHandleSelectOrDeselectAll();
    };

    const globalHandleSubmit = () => {
        existingHandleSubmit();
        scheduledHandleSubmit();
    };

    useEffect(() => {
        if (existingPassthroughError || scheduledPassthroughError) {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: t("errors:no-items-selected")
                })
            );

            setExistingPassthroughError(null);
            setScheduledPassthroughError(null);
        }
    }, [
        existingPassthroughError,
        scheduledPassthroughError,
        dispatch,
        t,
        setExistingPassthroughError,
        setScheduledPassthroughError
    ]);

    // Render
    if (loading) {
        return (
            <div className={styles["loading-wrapper"]}>
                <Spinner />
            </div>
        );
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <>
            <div className={styles["content"]}>
                <Tabs<VideoFilters>
                    tabs={[
                        {
                            id: VideoFilters.VideoLibrary,
                            label: t("page-titles:video-library"),
                            component: ExistingAddVideosContentComponent
                        },
                        {
                            id: VideoFilters.Scheduled,
                            label: t("collection-page:scheduled-livestreams"),
                            component: (
                                <>
                                    <p className="schedule-livestream-cta">
                                        <Trans
                                            i18nKey={
                                                "video-player-page:schedule-livestream-cta"
                                            }
                                            components={{
                                                link1: (
                                                    <Link
                                                        to={
                                                            "https://itunes.apple.com/us/app/switcher-studio-pro/id908386221"
                                                        }
                                                        target="_blank"
                                                    />
                                                )
                                            }}
                                        />
                                    </p>
                                    {ScheduledGenericMultiSelectComponent}
                                </>
                            )
                        }
                    ]}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            </div>
            <hr />
            <div className={styles["footer"]}>
                <button
                    type="button"
                    className="btn"
                    onClick={() => setIsOpen(false)}
                >
                    {t("buttons:cancel")}
                </button>
                <button
                    type="button"
                    className="btn"
                    onClick={globalHandleSelectOrDeselectAll}
                    disabled={!globalVisibleItems?.length}
                >
                    {globalAnyVisibleSelected && globalAnyNewSelected
                        ? t("buttons:deselect-all")
                        : t("buttons:select-all")}
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={globalHandleSubmit}
                    disabled={!globalAnyNewSelected}
                >
                    {t("video-player-page:add-to-collection")} (
                    {numberCurrentlySelected})
                </button>
            </div>
        </>
    );
};
