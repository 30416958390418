import styles from "./index.module.scss";
import PreviewIcon from "assets/icons/preview.svg?react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactNode, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import Loading from "components/loading/Loading";

export interface EmbedPreviewProps {
    title?: string;
    icon?: ReactNode;
    embedString: string;
    isVertical?: boolean;
    footer?: ReactNode;
    playerId: string;
    hasMaxHeight?: boolean;
}

export const EmbedPreview = ({
    title,
    icon,
    embedString,
    isVertical,
    footer,
    playerId,
    hasMaxHeight = false
}: EmbedPreviewProps) => {
    const { t } = useTranslation();
    const user = useSelector((state: RootState) => state.user);
    const embedRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState(true);

    const iconColor = {
        backgroundColor: "#0C4F60"
    };
    const previewTitle = useMemo(() => {
        return title || t("catalog-page:settings-title");
    }, [title, t]);

    useEffect(() => {
        if (!embedString && !embedRef.current) return;
        const slotHtml = document
            .createRange()
            .createContextualFragment(embedString);
        embedRef.current.innerHTML = "";
        const selectedElement = slotHtml.querySelector(
            ".dff402f7-5be0-4890-b831-95c5b63ddb42"
        ) as any;
        if (selectedElement) {
            selectedElement.dataset.userid = user.userInfo.UserId;
        }
        embedRef.current.appendChild(slotHtml);
    }, [embedString, user.userInfo.UserId]);

    useEffect(() => {
        const addEmbedLoadedListener = () => {
            if (!playerId) return;
            const player =
                window.switcherPlayerApp?.playerCollection?.[playerId];
            if (!!player?.mainIframe) {
                player?.mainIframe?.addEventListener(
                    "switcherEmbedLoaded",
                    () => setIsLoading(false)
                );
            }
        };

        window.addEventListener(
            "switcherPlayerAppLoaded",
            addEmbedLoadedListener
        );
        return () =>
            window.removeEventListener(
                "switcherPlayerAppLoaded",
                addEmbedLoadedListener
            );
    }, [playerId]);

    return (
        <div className={`${styles["widget"]} ${styles["is-player-page"]} `}>
            <div className={`${styles["title"]} ${styles["no-border"]}`}>
                <div className={styles["icon"]} style={iconColor}>
                    {icon || <PreviewIcon />}
                </div>
                <div
                    className={`${styles["title-text"]} ${styles["title-color"]}`}
                >
                    {previewTitle}
                </div>
            </div>
            <div className={styles["embedded-video-wrapper"]}>
                <Loading isLoading={isLoading} />
                <div
                    className={`${styles["embedded-video"]} ${
                        hasMaxHeight ? styles["max-height"] : ""
                    } ${isVertical ? styles["is-vertical"] : ""}`}
                    ref={embedRef}
                />
            </div>
            {footer && <div className={styles["footer"]}>{footer}</div>}
        </div>
    );
};
