import { useSwitcherClient } from "hooks/useSwitcherClient";
import React, { useMemo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import {
    BroadcastDetails,
    DisabledVariant
} from "../../../../../components/entity-details/BroadcastDetails";
import { useDispatch } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { AppDispatch } from "store/store";
import { sortCloudflareVideosAsc } from "helpers/cloudVideosHelpers";
import { CloudRecordingsEmptyState } from "views/page-content/cloud-videos/CloudRecordingsEmptyState";
import { Suspense } from "react";
import { Spinner } from "components/spinners/Spinner";
import { closeCurrentModal, setActiveModal } from "store/modal/slice";
import { Modals } from "store/modal/types";
import { Button } from "components/buttons/Button";
import { DetailsVariant } from "components/entity-details/BaseDetails";
import { BroadcastStatus } from "@switcherstudio/switcher-api-client";
import { AddVideosModal } from "components/modal/AddVideosModal";

export enum FeaturedVideoType {
    VideoPlayer = "VideoPlayer",
    Catalog = "Catalog"
}

export const SpecificVideo = ({
    selectedBroadcastId,
    onSelectBroadcastId,
    videoPlayerId,
    featuredVideoType = FeaturedVideoType.VideoPlayer,
    disabled = false,
    disabledVariant = featuredVideoType == FeaturedVideoType.Catalog
        ? DisabledVariant.NoInteract
        : DisabledVariant.Disabled
}: {
    selectedBroadcastId: string;
    onSelectBroadcastId: (broadcastId: string) => void;
    videoPlayerId: string;
    featuredVideoType?: "VideoPlayer" | "Catalog";
    disabled?: boolean;
    disabledVariant?: DisabledVariant;
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const {
        data: selectedBroadcasts,
        dispatchApiRequest: getVideosForUser,
        loading: videosLoading
    } = useSwitcherClient(
        (client) => client.cloudRecordings_GetVideosForUserV2,
        {
            requestImmediately: false,
            hideLoading: featuredVideoType == FeaturedVideoType.Catalog,
            onError: () => {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("errors:recording-retrieval-error")
                    })
                );
            }
        }
    );

    const { data: cloudUsage } = useSwitcherClient(
        (client) => client.cloudRecordings_GetUsage,
        {
            requestImmediately: true,
            hideLoading: true
        }
    );

    const closeModal = useCallback(() => {
        dispatch(closeCurrentModal());
    }, [dispatch]);

    useEffect(() => {
        getVideosForUser([
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            [selectedBroadcastId]
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBroadcastId]);

    const handleOpenModal = useCallback(async () => {
        dispatch(
            setActiveModal({
                id: Modals.AddVideosModal,
                type: Modals.AddVideosModal,
                component: (
                    <AddVideosModal
                        modalTitle={t("player-status-page:modal-title")}
                        isMultiple={false}
                        allowAdditional={false}
                        isOpen
                        onSelect={(broadcastIds) => {
                            onSelectBroadcastId(broadcastIds[0]);
                        }}
                        playerId={videoPlayerId}
                        previouslySelectedBroadcastIds={[selectedBroadcastId]}
                        setIsOpen={closeModal}
                        buttonText={
                            !selectedBroadcastId
                                ? t("player-status-page:buttons:select-video")
                                : t("player-status-page:buttons:save-changes")
                        }
                        showUnpublished={false}
                        onClose={(broadcastId) =>
                            onSelectBroadcastId(broadcastId)
                        }
                        useAddVideosLegacy={true}
                    />
                )
            })
        );
    }, [
        closeModal,
        dispatch,
        onSelectBroadcastId,
        selectedBroadcastId,
        t,
        videoPlayerId
    ]);

    const selectedBroadcast = useMemo(() => {
        return selectedBroadcasts?.find(
            (response) =>
                response.broadcast?.Id === selectedBroadcastId &&
                // filter out unpublished videos
                response.broadcast?.BroadcastStatus !== BroadcastStatus._5
        );
    }, [selectedBroadcasts, selectedBroadcastId]);

    const selectedBroadcastVideo = useMemo(() => {
        return sortCloudflareVideosAsc(selectedBroadcast?.videos)?.[0];
    }, [selectedBroadcast]);

    if (videosLoading) {
        return <Spinner />;
    }

    return (
        <div className={styles["specific-video-container"]}>
            <div className={styles["specific-video-content-container"]}>
                {cloudUsage?.TotalRecordings > 0 ? (
                    <>
                        <BroadcastDetails
                            video={selectedBroadcastVideo}
                            broadcast={selectedBroadcast?.broadcast}
                            allowVideoPlaybackOnThumbnailClick={!disabled}
                            location={"player-playlist"}
                            disabled={disabled}
                            disabledVariant={disabledVariant}
                            variant={
                                featuredVideoType == FeaturedVideoType.Catalog
                                    ? DetailsVariant.Vertical
                                    : DetailsVariant.Horizontal
                            }
                        />
                        <Button
                            type={
                                featuredVideoType == FeaturedVideoType.Catalog
                                    ? "badge-secondary"
                                    : "box"
                            }
                            onClick={handleOpenModal}
                            disabled={disabled}
                        >
                            {selectedBroadcast
                                ? t("player-status-page:buttons:change-video")
                                : t("player-status-page:buttons:select")}
                        </Button>
                    </>
                ) : (
                    <CloudRecordingsEmptyState
                        uploadVideoButton={
                            <Suspense>
                                <button
                                    className={`btn btn-primary ${styles["upload-button"]}`}
                                    onClick={handleOpenModal}
                                >
                                    {t("video-upload:upload-video")}
                                </button>
                            </Suspense>
                        }
                    />
                )}
            </div>
        </div>
    );
};
