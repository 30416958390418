import { redirectTo, routeLoader } from "router/loaders";
import { CatalogPage } from "views/page-content/CatalogPage";
import { CatalogCustomizationPage } from "views/page-content/CatalogPage/CatalogCustomizationPage";
import { CatalogGatingOptionsPage } from "views/page-content/CatalogPage/CatalogGatingOptionsPage";
import { CollectionPage } from "views/page-content/CatalogPage/CollectionPage";
import { CollectionCustomizationPage } from "views/page-content/CatalogPage/CollectionPage/CollectionCustomizationPage";
import { CollectionDefaultDisplayPage } from "views/page-content/CatalogPage/CollectionPage/CollectionDefaultDisplayPage";
import { CollectionGatedContentPage } from "views/page-content/CatalogPage/CollectionPage/CollectionGatedContentPage";
import { CollectionLinksContentPage } from "views/page-content/CatalogPage/CollectionPage/CollectionLinksContentPage";
import { CollectionPageV2 } from "views/page-content/CatalogPage/CollectionPage/CollectionPageV2";
import { FeaturedTrailerPage } from "views/page-content/CatalogPage/FeaturedTrailerPage";
import { CollectionVideoDetailsPage } from "views/page-content/VideoDetailsPage/CollectionVideoDetailsPage";

const enableUpcomingContent =
    import.meta.env.VITE_ENABLE_UPCOMING_CONTENT === "true";

const catalogRouteData = {
    badgeConfig: {
        claimCheck: false,
        claim: "videoplayer",
        label: "preview",
        class: "badge-preview"
    },
    upgradeConfig: {
        claimCheck: false,
        claim: "videoplayer",
        label: "upgrade-business"
    }
};

export const CatalogRoutes = [
    {
        path: "catalog",
        element: <CatalogPage />
    },
    {
        path: "catalog/featured-trailer",
        element: <FeaturedTrailerPage />
    },
    {
        path: "catalog/customization",
        element: <CatalogCustomizationPage />
    },
    {
        path: "catalog/catalog-subscription",
        element: <CatalogGatingOptionsPage />
    },
    {
        path: "catalog/collections",
        loader: redirectTo("/catalog")
    },
    {
        path: "catalog/collections/:videoPlayerId",
        element: enableUpcomingContent ? (
            <CollectionPageV2 />
        ) : (
            <CollectionPage />
        ),
        loader: routeLoader({
            data: catalogRouteData
        })
    },
    {
        path: "catalog/collections/:videoPlayerId/gated-content",
        element: <CollectionGatedContentPage />,
        loader: routeLoader({
            data: catalogRouteData
        })
    },
    {
        path: "catalog/collections/:videoPlayerId/default-display",
        element: <CollectionDefaultDisplayPage />,
        loader: routeLoader({
            data: catalogRouteData
        })
    },
    {
        path: "catalog/collections/:videoPlayerId/links",
        element: <CollectionLinksContentPage />,
        loader: routeLoader({
            data: catalogRouteData
        })
    },
    {
        path: "catalog/collections/:videoPlayerId/customization",
        element: <CollectionCustomizationPage />,
        loader: routeLoader({
            data: catalogRouteData
        })
    },
    {
        path: "catalog/collections/:videoPlayerId/video/:playlistBroadcastId",
        element: <CollectionVideoDetailsPage />,
        loader: routeLoader({
            data: catalogRouteData
        })
    }
];
