import React, { useCallback, useMemo } from "react";
import { usePageHeader } from "hooks/usePageHeader";
import { Trans, useTranslation } from "react-i18next";
import { useCatalogAccessBanner } from "../hooks/useCatalogAccessBanner";
import { StudioUpgradeCta } from "components/upgrade-prompt/StudioUpgradeCta";
import { PasswordGatingToggle } from "components/inputs/toggle/PasswordGatingToggle";
import { useCatalogGating } from "./useCatalogGating";
import { Button } from "components/buttons/Button";
import styles from "./index.module.scss";
import { useBeforeUnload } from "hooks/useBeforeUnload";
import { Toggle } from "components/inputs/toggle/Toggle";
import { CatalogPurchaseOptions } from "./CatalogPurchaseOptions";
import { useExpandedGating } from "./useExpandedGating";
import { PricingModalTypes } from "components/modal/GatedContentModal";
import { Spinner } from "components/spinners/Spinner";
import { GatedContentStatus } from "hooks/useUserStripeData";
import { StripeConnectWrapper } from "components/stripe/StripeConnectWrapper";

export const CatalogGatingOptionsPage: React.FC = () => {
    const { t } = useTranslation("subscription-page");
    useCatalogAccessBanner();

    // Handle Expanded Gating Options
    const {
        hasPasswordProtectedContentClaim,
        hasEmailProtectedContentClaim,
        hasExtraGatingOptions,
        oneTimePassFeatureEnabled,
        hasGatedContentClaim
    } = useExpandedGating();

    const subtitleKey = useMemo(() => {
        if (hasPasswordProtectedContentClaim && hasEmailProtectedContentClaim) {
            return t("subscription-page:subscription-options-all");
        } else if (hasPasswordProtectedContentClaim) {
            return t("subscription-page:subscription-options-password");
        } else if (hasEmailProtectedContentClaim) {
            return t("subscription-page:subscription-options-email");
        } else {
            return t("subscription-page:subscription-options-subtitle");
        }
    }, [hasPasswordProtectedContentClaim, hasEmailProtectedContentClaim, t]);

    usePageHeader({
        title: hasExtraGatingOptions
            ? t("subscription-page:gating-options")
            : t("subscription-page:subscription-options"),
        showBreadcrumbs: true,
        breadcrumbLabels: [
            t("breadcrumbs:catalog"),
            hasExtraGatingOptions
                ? t("subscription-page:gating-options-crumb")
                : t("subscription-page:subscription-options")
        ],
        subTitle: <Trans i18nKey={t(subtitleKey)} />
    });

    /* The form use hook */
    const {
        loading,
        gatedContentStatus,
        formValues,
        priceErrors,
        thereIsAnOldProduct,
        handlePriceChange,
        catalogHasNoChanges,
        entitlementAndPrices,
        hasPasswordValidationError,
        onSubmit,
        untimedOTPInitiallySelected,
        link
    } = useCatalogGating();

    const isSetup = gatedContentStatus === GatedContentStatus.READY;

    /* Callbacks for form actions */
    const onToggleEmail = useCallback(
        (state: boolean | ((prevState: boolean) => boolean)) => {
            handlePriceChange("isEmailGatingEnabled", state);
        },
        [handlePriceChange]
    );

    const onTogglePurchaseOptions = useCallback(
        (state: boolean | ((prevState: boolean) => boolean)) => {
            handlePriceChange("isPurchaseGatingEnabled", state);
        },
        [handlePriceChange]
    );

    useBeforeUnload(isSetup && !catalogHasNoChanges, null, true);

    if (loading) return <Spinner />;

    if (!hasGatedContentClaim) return <StudioUpgradeCta variant="catalog" />;

    return (
        <>
            <div className={styles["gating-options"]}>
                {hasPasswordProtectedContentClaim && (
                    <PasswordGatingToggle
                        isEnabled={formValues?.isPasswordGatingEnabled}
                        setIsEnabled={(isEnabled) =>
                            handlePriceChange(
                                "isPasswordGatingEnabled",
                                isEnabled
                            )
                        }
                        password={formValues?.password}
                        setPassword={(password) =>
                            handlePriceChange("password", password)
                        }
                        hasEmailOrPurchaseGating={
                            formValues?.isEmailGatingEnabled ||
                            formValues?.isPurchaseGatingEnabled
                        }
                        hasValidationError={hasPasswordValidationError}
                    />
                )}
                {hasEmailProtectedContentClaim && (
                    <div className={styles["email-section"]}>
                        <Toggle
                            on={
                                formValues?.isPurchaseGatingEnabled ||
                                formValues?.isEmailGatingEnabled
                            }
                            label={t("catalog-gating-page:email-gating-label")}
                            reverseLayout={true}
                            onToggle={onToggleEmail}
                            disabled={formValues?.isPurchaseGatingEnabled}
                        />
                    </div>
                )}
                {hasExtraGatingOptions && (
                    <div className={styles["email-section"]}>
                        <Toggle
                            on={formValues?.isPurchaseGatingEnabled}
                            label={t("catalog-gating-page:require-purchase")}
                            reverseLayout={true}
                            onToggle={onTogglePurchaseOptions}
                        />
                    </div>
                )}
            </div>

            {!isSetup && hasExtraGatingOptions && (
                <Button
                    type="primary"
                    isSubmit
                    onClick={onSubmit}
                    disabled={catalogHasNoChanges}
                >
                    {t("buttons:save-changes")}
                </Button>
            )}

            {formValues && (
                <StripeConnectWrapper
                    gatedContentStatus={gatedContentStatus}
                    href={link.href}
                    loading={loading}
                >
                    <div className={styles["stripe-container"]}>
                        {(formValues?.isPurchaseGatingEnabled ||
                            !hasExtraGatingOptions) && (
                            <CatalogPurchaseOptions
                                formValues={formValues}
                                priceErrors={priceErrors}
                                handlePriceChange={handlePriceChange}
                                createOrUpdate={
                                    thereIsAnOldProduct
                                        ? PricingModalTypes.Update
                                        : PricingModalTypes.Create
                                }
                                entitlementAndPrices={entitlementAndPrices}
                                oneTimePassFeatureEnabled={
                                    oneTimePassFeatureEnabled
                                }
                                untimedOTPInitiallySelected={
                                    untimedOTPInitiallySelected
                                }
                            />
                        )}
                    </div>
                </StripeConnectWrapper>
            )}

            {isSetup && (
                <Button
                    type="primary"
                    isSubmit
                    onClick={onSubmit}
                    disabled={catalogHasNoChanges}
                >
                    {t("buttons:save-changes")}
                </Button>
            )}
        </>
    );
};
