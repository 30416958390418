import React, {
    useEffect,
    useState,
    useCallback,
    useMemo,
    useRef
} from "react";
import styles from "./index.module.scss";
import SwitcherPlayerSettingsIcon from "assets/icons/switcher-player-settings.svg?react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import { RootState } from "store/reducers";
import { useTranslation } from "react-i18next";
import rollbar from "helpers/rollbar";
import {
    VideoPlayerAspectRatio,
    VideoPlayerEmbeddedDisplay,
    VideoPlayerProfile,
    VideoPlayerResponse
} from "@switcherstudio/switcher-api-client";
import { Errors, useForm } from "hooks/useForm";
import { usePageHeader } from "hooks/usePageHeader";
import { Trans } from "react-i18next";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { useBeforeUnload } from "hooks/useBeforeUnload";
import { Tabs } from "components/tabs/Tabs";
import { CustomizationTabs } from "../../types";
import { DisplayTab } from "./DisplayTab";
import { StylesTab } from "./StylesTab";
import { Button } from "components/buttons/Button";
import { EmbedPreview } from "components/embed-preview";
import {
    defaultInteractiveTabMap,
    embeddedDisplayMap,
    idleStateMap
} from "helpers/mappers/playerApiMaps";
import { useCatalogAccessBanner } from "../../hooks/useCatalogAccessBanner";
import { useParams } from "hooks/useParams";

declare global {
    interface Window {
        switcherPlayerApp: any;
    }
}

export const CollectionCustomizationPage: React.FC = () => {
    const { videoPlayerId } = useParams();
    useCatalogAccessBanner();
    const { t } = useTranslation("customization-page");
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.user);

    const [customizationActiveTab, setCustomizationActiveTab] =
        useState<CustomizationTabs>(CustomizationTabs.Display);

    const projectId = user?.userInfo?.ProjectId;
    const [embedPreviewKey, setEmbedPreviewKey] = useState<number>(0);
    const [localCollectionResponse, setLocalCollectionResponse] =
        useState<VideoPlayerResponse>(null);
    const [initCollectionResponse, setInitCollectionResponse] =
        useState<VideoPlayerResponse>(null);
    const [hasPendingChanges, setHasPendingChanges] = useState<boolean>(false);
    const isMountedRef = useIsMountedRef();
    const formRef = useRef<HTMLFormElement>(null);

    const { loading: getVideoCollectionsLoading } = useSwitcherClient(
        (client) => client.videoPlayers_GetVideoPlayer,
        {
            requestImmediately: true,
            args: [videoPlayerId],
            onSuccess: (data) => {
                setLocalCollectionResponse(data);
                setInitCollectionResponse(data);
            }
        }
    );

    const {
        loading: updateCollectionLoading,
        dispatchApiRequest: updateCollection
    } = useSwitcherClient(
        (client) => client.projectsVideoPlayer_PutVideoPlayer
    );

    const loading = useMemo(
        () => getVideoCollectionsLoading || updateCollectionLoading,
        [getVideoCollectionsLoading, updateCollectionLoading]
    );

    const submitAction = useCallback(async () => {
        try {
            /** Save video player edits */
            await updateCollection([
                localCollectionResponse?.VideoPlayer?.Id,
                {
                    VideoPlayer: {
                        ...localCollectionResponse?.VideoPlayer
                    }
                },
                projectId
            ]);

            setInitCollectionResponse(localCollectionResponse);

            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message:
                        "customization-page:messages:collection-update-success"
                })
            );
        } catch (e) {
            rollbar.error("Error updating collection appearance", e);

            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: e.message
                })
            );
        }

        setEmbedPreviewKey((prev) => prev + 1);
    }, [dispatch, updateCollection, localCollectionResponse, projectId]);

    const handleCollectionSettingsChange = (key: string, value: any) => {
        let updatedProfile = { ...localCollectionResponse };
        updatedProfile.VideoPlayer = {
            ...updatedProfile.VideoPlayer,
            [key]: value === "" ? null : value
        };

        setLocalCollectionResponse(updatedProfile);
        customHandleChange(updatedProfile);
    };

    const {
        handleSubmit,
        /** only responsible for setting values internally */
        customHandleChange,
        errors
    } = useForm<VideoPlayerProfile>(
        localCollectionResponse,
        submitAction,
        (values) => {
            let errors: Errors = {};
            // eslint-disable-next-line
            const hexRegExp = /^#([0-9a-f]{6})$/i;
            if (
                values?.VideoPlayer?.PrimaryColor &&
                !hexRegExp.test(values?.VideoPlayer?.PrimaryColor)
            ) {
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                errors.VideoPlayerSettings.PrimaryColor =
                    t("errors:color-error");
            }
            if (
                values?.VideoPlayer?.SecondaryColor &&
                !hexRegExp.test(values?.VideoPlayer?.SecondaryColor)
            ) {
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                errors.VideoPlayerSettings.SecondaryColor =
                    t("errors:color-error");
            }
            if (
                values?.VideoPlayer?.LogoBackgroundColor &&
                !hexRegExp.test(values?.VideoPlayer?.LogoBackgroundColor)
            ) {
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                errors.VideoPlayerSettings.LogoBackgroundColor =
                    t("errors:color-error");
            }
            if (
                values?.VideoPlayer?.EmbedBackgroundColor &&
                !hexRegExp.test(values?.VideoPlayer?.EmbedBackgroundColor)
            ) {
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                errors.VideoPlayerSettings.EmbedBackgroundColor =
                    t("errors:color-error");
            }
            if (
                values?.VideoPlayer?.EmbedTextColor &&
                !hexRegExp.test(values?.VideoPlayer?.EmbedTextColor)
            ) {
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                errors.VideoPlayerSettings.EmbedTextColor =
                    t("errors:color-error");
            }
            if (
                values?.VideoPlayer?.InteractivePanelBackgroundColor &&
                !hexRegExp.test(
                    values?.VideoPlayer?.InteractivePanelBackgroundColor
                )
            ) {
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                errors.VideoPlayerSettings.InteractivePanelBackgroundColor =
                    t("errors:color-error");
            }
            if (
                values?.VideoPlayer?.InteractivePanelTextColor &&
                !hexRegExp.test(values?.VideoPlayer?.InteractivePanelTextColor)
            ) {
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                errors.VideoPlayerSettings.InteractivePanelTextColor =
                    t("errors:color-error");
            }
            if (
                values?.VideoPlayer?.EnableMaxEmbedWidth &&
                values?.VideoPlayer?.MaxEmbedWidth &&
                parseInt(values?.VideoPlayer?.MaxEmbedWidth) < 320
            ) {
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                errors.VideoPlayerSettings.MaxEmbedWidth = t(
                    "errors:max-embed-width"
                );
            }

            return errors;
        },
        false
    );

    useEffect(() => {
        setHasPendingChanges(
            JSON.stringify(localCollectionResponse) !==
                JSON.stringify(initCollectionResponse)
        );
    }, [initCollectionResponse, localCollectionResponse, setHasPendingChanges]);

    /** Trigger form submission from outside form */
    const handleSaveButtonClick = () => {
        if (formRef.current) {
            handleSubmit();
        }
    };

    const handleRevertButtonClick = () => {
        if (formRef.current) {
            setLocalCollectionResponse(initCollectionResponse);
        }
    };

    usePageHeader({
        title: t("customization-page:customization"),
        showBreadcrumbs: true,
        breadcrumbLabels: [
            t("breadcrumbs:catalog"),
            t("collection-page:collections-settings"),
            t("customization-page:customization")
        ],
        subTitle: (
            <Trans i18nKey={t("customization-page:customization-subtitle")} />
        ),
        backNavigationText: t("customization-page:collection-settings")
    });

    /** Send the updated video profile values to the player embed.
     * We are accessing a method written in the player project and
     * exposed by penpal. We are calling that method with the new values
     * and updating the player preview with those values. */
    useEffect(() => {
        try {
            if (isMountedRef.current) {
                const profileUpdate = {
                    ...localCollectionResponse?.VideoPlayer,
                    PrimaryColor:
                        localCollectionResponse?.VideoPlayer?.PrimaryColor?.toLocaleUpperCase() ??
                        "#000000",
                    SecondaryColor:
                        localCollectionResponse?.VideoPlayer?.SecondaryColor?.toLocaleUpperCase() ??
                        "#FFFFFF",
                    LogoBackgroundColor:
                        localCollectionResponse?.VideoPlayer?.LogoBackgroundColor?.toLocaleUpperCase() ??
                        "#000000",
                    PlayerControlsColor:
                        localCollectionResponse?.VideoPlayer?.PlayerControlsColor?.toLocaleUpperCase() ??
                        "#FFFFFF",
                    EmbedBackgroundColor:
                        localCollectionResponse?.VideoPlayer?.EmbedBackgroundColor?.toLocaleUpperCase() ??
                        "FFFFFF",
                    EmbedTextColor:
                        localCollectionResponse?.VideoPlayer?.EmbedTextColor?.toLocaleUpperCase() ??
                        "000000",
                    InteractivePanelBackgroundColor:
                        localCollectionResponse?.VideoPlayer?.InteractivePanelBackgroundColor?.toLocaleUpperCase() ??
                        "FFFFFF",
                    InteractivePanelTextColor:
                        localCollectionResponse?.VideoPlayer?.InteractivePanelTextColor?.toLocaleUpperCase() ??
                        "000000",
                    UpcomingTextColor:
                        localCollectionResponse?.VideoPlayer?.UpcomingTextColor?.toLocaleUpperCase() ??
                        "FFFFFF",
                    EmbeddedDisplay:
                        embeddedDisplayMap[
                            localCollectionResponse?.VideoPlayer
                                ?.EmbeddedDisplay
                        ],
                    DefaultInteractiveTab:
                        defaultInteractiveTabMap[
                            localCollectionResponse?.VideoPlayer
                                ?.DefaultInteractiveTab
                        ],
                    IdleState:
                        idleStateMap[
                            localCollectionResponse?.VideoPlayer?.IdleState
                        ],
                    VideoPlayerPlaylists: undefined
                };

                // vp expects camelCase keys
                const mappedProfileUpdate = Object.keys(profileUpdate).reduce(
                    (acc, key) => {
                        const newKey =
                            key.charAt(0).toLowerCase() + key.slice(1);
                        acc[newKey] = profileUpdate[key];
                        return acc;
                    },
                    {}
                );

                window.switcherPlayerApp?.playerCollection[
                    localCollectionResponse?.VideoPlayer?.Id
                ]?.mainConnection?.setCollectionDetails(mappedProfileUpdate);
                window.switcherPlayerApp?.playerCollection[
                    localCollectionResponse?.VideoPlayer?.Id
                ]?.auxConnection?.setCollectionDetails(mappedProfileUpdate);
            }
        } catch (e) {}
    }, [localCollectionResponse?.VideoPlayer, isMountedRef]);

    useBeforeUnload(hasPendingChanges, null, true);

    if (localCollectionResponse === null) return null;
    return (
        <div className="row">
            <div className="col-lg-4">
                <form
                    onSubmit={handleSubmit}
                    onKeyDown={(e) =>
                        e.key === "Enter" ? handleSubmit(e) : null
                    }
                    ref={formRef}
                >
                    <Tabs<CustomizationTabs>
                        tabs={[
                            {
                                id: CustomizationTabs.Display,
                                label: t("customization-page:display"),
                                component: (
                                    <DisplayTab
                                        errors={errors}
                                        handleSettingsChange={
                                            handleCollectionSettingsChange
                                        }
                                        localVideoPlayerResponse={
                                            localCollectionResponse
                                        }
                                        videoPlayerValues={
                                            localCollectionResponse
                                        }
                                    />
                                )
                            },
                            {
                                id: CustomizationTabs.Styles,
                                label: t("customization-page:styles"),
                                component: (
                                    <StylesTab
                                        errors={errors}
                                        handleSettingsChange={
                                            handleCollectionSettingsChange
                                        }
                                        videoPlayerValues={
                                            localCollectionResponse
                                        }
                                    />
                                )
                            }
                        ]}
                        activeTab={customizationActiveTab}
                        setActiveTab={(id) => setCustomizationActiveTab(id)}
                    />
                </form>
            </div>
            <div className={`col-lg-8 ${styles["sticky-container"]}`}>
                <div className={`${styles["button-container"]}`}>
                    <Button
                        onClick={handleRevertButtonClick}
                        disabled={loading || !hasPendingChanges}
                        type="outline"
                    >
                        {t("buttons:revert").toUpperCase()}
                    </Button>
                    <Button
                        onClick={handleSaveButtonClick}
                        disabled={loading || !hasPendingChanges}
                        type="primary"
                    >
                        {t("buttons:save-changes").toUpperCase()}
                    </Button>
                </div>
                <EmbedPreview
                    title={t("video-player-page:player-preview")}
                    icon={<SwitcherPlayerSettingsIcon />}
                    isVertical={
                        localCollectionResponse?.VideoPlayer
                            ?.EmbeddedDisplay ===
                            VideoPlayerEmbeddedDisplay._0 &&
                        localCollectionResponse?.VideoPlayer?.AspectRatio ===
                            VideoPlayerAspectRatio.NineBySixteen
                    }
                    embedString={localCollectionResponse?.EmbedCode}
                    playerId={localCollectionResponse?.VideoPlayer?.Id}
                    key={embedPreviewKey}
                    hasMaxHeight={
                        localCollectionResponse?.VideoPlayer?.AspectRatio ===
                        VideoPlayerAspectRatio.NineBySixteen
                    }
                />
            </div>
        </div>
    );
};
