import React, { useCallback } from "react";
import { CreatorProductPrice } from "@switcherstudio/switcher-api-client";
import styles from "components/badges/index.module.scss";
import ShopIcon from "assets/icons/product-tag.svg?react";
import PlaylistIcon from "assets/icons/switcher-player-rev.svg?react";
import TicketIcon from "assets/icons/gated.svg?react";
import _LockIcon from "assets/icons/lock.svg?react";
import HiddenIcon from "assets/icons/hidden.svg?react";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { Badge } from "../../../badges/types";
import { exists } from "helpers/booleans";
import { useClaimCheck } from "hooks/useClaimCheck";
import {
    BroadcastResponse,
    CreatorEntitlementResponse,
    CreatorPriceResponse
} from "@switcherstudio/api-core-client";

export type EntitlementType =
    | "one-time-pass"
    | "subscription"
    | "redemption"
    | "all";

interface VideoBadgesV2Props {
    broadcast: BroadcastResponse;
    entitlements?: CreatorEntitlementResponse[];
    // if true, badges show count for each type of entitlement (e.g. "1 One-Time Pass"),
    // if false, badges display each individual entitlement
    groupEntitlements?: boolean;
    shownEntitlements?: Array<EntitlementType>;
}

const LockIcon: React.FC = () => <_LockIcon width={12} height={12} />;

export const VideoBadgesV2: React.FC<VideoBadgesV2Props> = ({
    broadcast,
    entitlements,
    groupEntitlements = false,
    shownEntitlements = []
}: VideoBadgesV2Props) => {
    const { t } = useTranslation();
    const hasCatalogClaim = useClaimCheck("catalog");

    const { oneTimePassesCount, subscriptionsCount } = useMemo(
        () =>
            entitlements?.reduce(
                (memo, currentEntitlement) => {
                    const recurringInterval =
                        (
                            currentEntitlement?.Product
                                ?.Prices?.[0] as CreatorProductPrice
                        )?.RecurringInterval ??
                        (
                            currentEntitlement?.Product
                                ?.Prices?.[0] as CreatorPriceResponse
                        )?.Details?.RecurringInterval;
                    const isRecurring = exists(recurringInterval);
                    return {
                        oneTimePassesCount: isRecurring
                            ? memo.oneTimePassesCount
                            : memo.oneTimePassesCount + 1,
                        subscriptionsCount: isRecurring
                            ? memo.subscriptionsCount + 1
                            : memo.subscriptionsCount
                    };
                },
                { oneTimePassesCount: 0, subscriptionsCount: 0 }
            ) ?? {
                oneTimePassesCount: undefined,
                subscriptionsCount: undefined
            },
        [entitlements]
    );

    const showOneTimePasses = useMemo<boolean>(
        () =>
            oneTimePassesCount > 0 &&
            (shownEntitlements.includes("one-time-pass") ||
                shownEntitlements.includes("all")),
        [oneTimePassesCount, shownEntitlements]
    );

    const showSubscriptions = useMemo<boolean>(
        () =>
            subscriptionsCount > 0 &&
            (shownEntitlements.includes("subscription") ||
                shownEntitlements.includes("all")),
        [shownEntitlements, subscriptionsCount]
    );

    const showRedemptions = useMemo<boolean>(
        () =>
            shownEntitlements.includes("redemption") ||
            shownEntitlements.includes("all"),
        [shownEntitlements]
    );

    const getEntitlementType = useCallback(
        (entitlement: CreatorEntitlementResponse): EntitlementType => {
            return exists(
                entitlement?.Product?.Prices?.[0]?.Details?.RecurringInterval
            )
                ? "subscription"
                : "one-time-pass";
        },
        []
    );

    const videoBadges = useMemo<Badge[]>(() => {
        const players = broadcast?.Collections;
        const isInPlaylist = players?.length > 0;
        const isShoppable = broadcast?.Details?.EnableLiveShopping;
        const isHidden = broadcast && !broadcast?.Details?.ShowInCatalog;
        const hasEntitlement = exists(entitlements) && entitlements?.length > 0;

        const collectionVideos =
            broadcast.Collections?.[0]?.CollectionVideos ?? [];
        const playlistBroadcast = collectionVideos.find(
            (video) => video?.Details?.Id === broadcast?.Details?.Id
        );
        const isEmailGatingEnabled =
            playlistBroadcast?.Details?.IsEmailGatingEnabled;
        const isPasswordGatingEnabled =
            playlistBroadcast?.Details?.IsPasswordGatingEnabled;

        const badges: Badge[] = [];
        if (isShoppable) {
            badges.push({
                slug: t("video-library:badges:shop"),
                icon: <ShopIcon />
            });
        }
        if (isInPlaylist) {
            badges.push({
                slug:
                    players?.length === 1
                        ? `1 ${
                              hasCatalogClaim
                                  ? t("video-library:badges:collection")
                                  : t("video-library:badges:playlist")
                          }`
                        : `${players?.length ?? 0} ${
                              hasCatalogClaim
                                  ? t("video-library:badges:collections")
                                  : t("video-library:badges:playlists")
                          }`,
                icon: <PlaylistIcon />
            });
        }
        if (isHidden) {
            badges.push({
                slug: t("video-library:badges:hidden"),
                icon: <HiddenIcon />
            });
        }
        if (hasEntitlement) {
            if (groupEntitlements) {
                if (showOneTimePasses) {
                    badges.push({
                        slug: t("video-badges:one-time-passes", {
                            count: oneTimePassesCount
                        }),
                        icon: <TicketIcon />
                    });
                }

                if (showSubscriptions) {
                    badges.push({
                        slug: t("video-badges:subscriptions", {
                            count: subscriptionsCount
                        }),
                        icon: <TicketIcon />
                    });
                }
            } else {
                entitlements.forEach((e) => {
                    const entitlementType = getEntitlementType(e);
                    const entitlementTypeIsShown =
                        (entitlementType === "subscription" &&
                            (shownEntitlements.includes("subscription") ||
                                shownEntitlements.includes("all"))) ||
                        (entitlementType === "one-time-pass" &&
                            (shownEntitlements.includes("one-time-pass") ||
                                shownEntitlements.includes("all")));
                    if (entitlementTypeIsShown) {
                        badges.push({
                            slug: `${e?.Product?.Details?.Name}`,
                            icon: <TicketIcon />
                        });
                    }
                });
            }
        }

        if (showRedemptions) {
            if (
                isEmailGatingEnabled &&
                isPasswordGatingEnabled &&
                !hasEntitlement
            ) {
                badges.push({
                    slug: t("video-badges:email-and-password"),
                    icon: <LockIcon />
                });
            } else if (isEmailGatingEnabled && !hasEntitlement) {
                badges.push({
                    slug: t("video-badges:email"),
                    icon: <LockIcon />
                });
            } else if (isPasswordGatingEnabled) {
                badges.push({
                    slug: t("video-badges:password"),
                    icon: <LockIcon />
                });
            }
        }
        return badges;
    }, [
        broadcast,
        entitlements,
        t,
        hasCatalogClaim,
        groupEntitlements,
        showOneTimePasses,
        showSubscriptions,
        showRedemptions,
        oneTimePassesCount,
        subscriptionsCount,
        shownEntitlements,
        getEntitlementType
    ]);

    return (
        <>
            {videoBadges.length > 0 && (
                <div className={`${styles["badges-container"]}`}>
                    {videoBadges.map((badge) => {
                        return (
                            <div
                                className={`${styles["badge"]} ${
                                    styles[`badge-${badge.slug}`]
                                }`}
                                key={badge.slug}
                            >
                                {badge.icon}
                                <p>{badge.slug}</p>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};
