import { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./index.module.scss";
import {
    CloudflareVideo,
    Broadcast,
    VideoPlayerPlaylistBroadcastEntitlementResponse,
    CreatorProductEntitlement,
    VideoPlayer,
    EntitySummaryResponse,
    BroadcastStatus,
    VideoPlayerPlaylistBroadcast
} from "@switcherstudio/switcher-api-client";
import { VideoBadges } from "components/badges/VideoBadges";
import {
    formatTimeDuration,
    isDayOf,
    isInFuture,
    localizeTime
} from "helpers/time";
import DownloadIcon from "assets/icons/download.svg?react";
import TrashIcon from "assets/icons/trash-sm.svg?react";
import ShareIcon from "assets/icons/share.svg?react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { exists } from "helpers/booleans";
import { useTranslation } from "react-i18next";
import { Button } from "components/buttons/Button";
import { GatedContentDisabledTooltip } from "components/tooltips/GatedContentDisabledTooltip";
import { GatedContentStatus } from "hooks/useUserStripeData";
import { shorten } from "helpers/strings";
import PencilIcon from "assets/icons/pencil-simple.svg?react";
import { ContextMenu } from "components/inputs/context-menu";
import { useIsMobile } from "hooks/useIsMobile";
import { VideoPlaybackModal } from "components/modal/VideoPlaybackModal";
import { DownloadProps, useVideoDownload } from "hooks/useVideoDownload";
import { VideoDownloadStatus } from "components/recording-card/RecordingCard/types";
import {
    ContextMenuItem,
    ContextMenuWrapper
} from "components/inputs/context-menu-button";
import { useClipboard } from "hooks/useClipboard";
import { BaseDetails, DetailsVariant } from "../BaseDetails";
import { BroadcastThumbnail } from "components/thumbnails/BroadcastThumbnail";
import { DetailRow } from "../BaseDetails/DetailRow";
import { ComponentMap } from "components/utility/ComponentMap";
import { useClaimCheck } from "hooks/useClaimCheck";
import { useVideoUpload } from "hooks/useVideoUpload";
import { VideoUploadStatus } from "store/uploads/types";
import { useInterval } from "hooks/useInterval";
import { useNavigate } from "react-router-dom";
dayjs.extend(relativeTime);

export enum DisabledVariant {
    NoInteract = "NoInteract",
    Disabled = "Disabled",
    Processing = "Processing"
}
export interface BroadcastDetailsProps {
    broadcast: Broadcast;
    collectionId?: string;
    playlistBroadcast?: Partial<VideoPlayerPlaylistBroadcastEntitlementResponse>;
    video?: CloudflareVideo;
    metrics?: EntitySummaryResponse;
    badges?: boolean;
    setPricingSelectModalIsOpen?: (...args: any) => any;
    setExistingEntitlement?: (...args: any) => any;
    setSelectedPlaylistBroadcastId?: (...args: any) => any;
    gatedContentStatus?: GatedContentStatus;
    showPricing?: boolean;
    productEntitlement?: CreatorProductEntitlement;
    showEdit?: boolean;
    onBroadcastUpdate?: () => void;
    handleDeleteBroadcast?: () => void;
    location?: "video-library" | "player-playlist";
    allowVideoPlaybackOnThumbnailClick?: boolean;
    showDownload?: boolean;
    players?: VideoPlayer[];
    entitlements?: CreatorProductEntitlement[] | undefined | null;
    disabled?: boolean;
    disabledVariant?: DisabledVariant;
    isDraggable?: boolean;
    variant?: DetailsVariant;
}

export const BroadcastDetails = ({
    video,
    broadcast,
    metrics,
    playlistBroadcast,
    badges,
    setPricingSelectModalIsOpen,
    setExistingEntitlement,
    setSelectedPlaylistBroadcastId,
    gatedContentStatus,
    showPricing = false,
    productEntitlement,
    showEdit = false,
    showDownload = false,
    handleDeleteBroadcast,
    location,
    allowVideoPlaybackOnThumbnailClick,
    players,
    entitlements,
    disabled = false,
    disabledVariant = DisabledVariant.Disabled,
    isDraggable = false,
    variant = DetailsVariant.Horizontal,
    collectionId
}: BroadcastDetailsProps) => {
    const { t } = useTranslation();
    const hasCatalogClaim = useClaimCheck("catalog");
    const { handleDownload, getDownloadStatus, getDownloadProgress } =
        useVideoDownload();
    const { copy } = useClipboard();
    const { getUploadStatus } = useVideoUpload();
    const [videoPlaybackModalIsOpen, setVideoPlaybackModalIsOpen] =
        useState(false);
    const navigate = useNavigate();

    const downloadStatus = useMemo<VideoDownloadStatus>(
        () =>
            getDownloadStatus({
                videoId: video?.uid
            } as DownloadProps),
        [video, getDownloadStatus]
    );

    const percentComplete = useMemo<number>(
        () =>
            getDownloadProgress({
                videoId: video?.uid
            } as DownloadProps),
        [getDownloadProgress, video?.uid]
    );

    const [timerEndTime, setTimerEndTime] = useState<Date>();
    const [timeRemaining, setTimeRemaining] = useState<number>(
        timerEndTime && timerEndTime?.getTime() < new Date().getTime()
            ? timerEndTime?.getTime() - new Date().getTime()
            : 0
    );
    const [triggerThumbnailUpdate, setTriggerThumbnailUpdate] =
        useState<number>(0);

    const videoState = useMemo<VideoUploadStatus>(() => {
        const isInitialStatusProcessingOrQueued =
            video?.status?.state === VideoUploadStatus.Processing ||
            video?.status?.state === VideoUploadStatus.Queued;
        if (isInitialStatusProcessingOrQueued)
            return getUploadStatus({ broadcastId: broadcast?.Id });

        return VideoUploadStatus.Success;
    }, [video?.status?.state, getUploadStatus, broadcast?.Id]);

    const isVideoProcessing = useMemo<boolean>(
        () => videoState !== VideoUploadStatus.Success,
        [videoState]
    );

    // This is used to trigger a thumbnail refresh when the scheduled event timer reaches 0.
    const triggerThumbnailRefresh = useCallback(() => {
        setTriggerThumbnailUpdate((prev) => prev + 1);
    }, [setTriggerThumbnailUpdate]);

    // This is used to calculate the time remaining for the scheduled event timer.
    const calcTimeRemaining = (timeEnd: Date) => {
        if (!timeEnd) return;
        const now = new Date().getTime();
        const distance = timeEnd.getTime() - now;

        setTimeRemaining(distance > 0 ? distance : 0);
    };

    // This will initialize the timer for the scheduled event when timerEndTime is set.
    useInterval(
        () => {
            calcTimeRemaining(timerEndTime);
        },
        timeRemaining > 0 ? 1000 : null
    );

    // Initial run of calcuation before interval begins.
    useEffect(() => {
        calcTimeRemaining(timerEndTime);
    }, [timerEndTime]);

    // Used to trigger refreshing some data (IE: thumbnails)
    // when the when the scheduled event timer reaches 0.
    useEffect(() => {
        if (timeRemaining > 0) return;

        triggerThumbnailRefresh();
    }, [timeRemaining, triggerThumbnailRefresh]);

    // If the broadcast is scheduled to premiere and is in the future,
    // it is considered a scheduled upload.
    const isScheduledUpload = useMemo(() => {
        let isScheduled =
            exists(broadcast?.StartsAt) &&
            isInFuture(broadcast?.StartsAt) &&
            broadcast?.BroadcastStatus === BroadcastStatus._3;

        return isScheduled;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [broadcast, triggerThumbnailUpdate]);

    // If the broadcast is scheduled to go live any time and has not started yet,
    // it is considered a scheduled live broadcast.
    const isScheduledLive = useMemo(() => {
        let isScheduled =
            exists(broadcast?.StartsAt) &&
            broadcast?.BroadcastStatus === BroadcastStatus._1;

        return isScheduled;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [broadcast, triggerThumbnailUpdate]);

    useEffect(() => {
        const isScheduled = isScheduledUpload || isScheduledLive;
        if (isScheduled && isInFuture(broadcast?.StartsAt)) {
            setTimerEndTime(new Date(broadcast?.StartsAt));
        }
    }, [broadcast, isScheduledLive, isScheduledUpload]);

    const startDate = useMemo(() => {
        if (!broadcast) return null;
        if (isScheduledUpload || isScheduledLive) {
            return new Date(broadcast.StartsAt);
        }
        return broadcast.ActiveAt
            ? new Date(broadcast.ActiveAt)
            : new Date(broadcast.CreatedAt);
    }, [broadcast, isScheduledLive, isScheduledUpload]);

    const dayOfBroadcast = useMemo(() => {
        return isDayOf(startDate?.toString());
    }, [startDate]);

    const localizedTime = useMemo(() => {
        return localizeTime(startDate?.toString(), "h:mm A");
    }, [startDate]);

    const isReadyForDownload = useMemo<boolean>(
        () => !!broadcast?.EndedAt,
        [broadcast]
    );

    const thumbnailImageURL = useMemo(
        () =>
            broadcast?.ThumbnailAsset?.SignedUrl ||
            (exists(video) ? video.thumbnail : ""),
        [broadcast, video]
    );

    const mobileCheck = useIsMobile();

    //This only disables the share button if a video is unpublished or archived. It does not prevent sharing of live videos.
    const shareable = useMemo(() => {
        return (
            broadcast?.BroadcastStatus !== BroadcastStatus._4 &&
            broadcast?.BroadcastStatus !== BroadcastStatus._5
        );
    }, [broadcast?.BroadcastStatus]);

    const isUnpublished = useMemo(() => {
        return broadcast?.BroadcastStatus === BroadcastStatus._5;
    }, [broadcast?.BroadcastStatus]);

    const shareOptions = useMemo<ContextMenuItem[]>(() => {
        return [
            {
                key: "copy-code",
                text: t("buttons:copy-embed-code"),
                onClick: () => copy(broadcast.EmbedCode)
            },
            {
                key: "copy-link",
                text: t("buttons:copy-share-link"),
                onClick: () => copy(broadcast.ShareLink)
            }
        ];
    }, [broadcast, copy, t]);

    const mobileButtonsMenuItems = useMemo(() => {
        if (disabled) {
            return [
                {
                    key: "Delete",
                    text: t("buttons:delete"),
                    onClick: handleDeleteBroadcast
                }
            ];
        }
        return [
            {
                key: "Edit",
                text: t("buttons:edit"),
                onClick: () =>
                    location === "video-library"
                        ? navigate(`/video-library/video/${broadcast.Id}`)
                        : navigate(
                              `/catalog/collections/${collectionId}/video/${playlistBroadcast?.Id}`
                          )
            },
            ...(location === "video-library"
                ? [
                      ...shareOptions,
                      ...(showDownload && [
                          {
                              key: "Download",
                              text: t("buttons:download"),
                              onClick: async () => {
                                  await handleDownload({
                                      broadcastId: broadcast.Id,
                                      videoId: video?.uid,
                                      title: broadcast.Title
                                  } as DownloadProps);
                              }
                          }
                      ]),
                      {
                          key: "Delete",
                          text: t("buttons:delete"),
                          onClick: handleDeleteBroadcast
                      }
                  ]
                : [
                      {
                          key: "Remove",
                          text: t("buttons:remove"),
                          onClick: handleDeleteBroadcast
                      }
                  ])
        ];
    }, [
        t,
        navigate,
        shareOptions,
        location,
        handleDeleteBroadcast,
        showDownload,
        handleDownload,
        broadcast?.Id,
        broadcast?.Title,
        video?.uid,
        disabled,
        playlistBroadcast?.Id,
        collectionId
    ]);
    const broadcastDetailsItems = useMemo(
        () => [
            ...(!isScheduledUpload && !isScheduledLive
                ? [
                      <span key="when" className={styles["when"]}>
                          {dayjs(startDate).fromNow()}
                      </span>
                  ]
                : []),
            ...(exists(video) &&
            exists(video.duration) &&
            !(video.duration === -1 && !broadcast?.InputId)
                ? [
                      <span key="duration" className={styles["duration"]}>
                          {video.duration === -1
                              ? t("widgets:currently-live")
                              : formatTimeDuration(video.duration)}
                      </span>
                  ]
                : []),
            ...(exists(metrics)
                ? [
                      <span key="views" className={styles["views"]}>
                          {metrics.TotalViews}{" "}
                          {location === "player-playlist"
                              ? metrics.TotalViews === 1
                                  ? hasCatalogClaim
                                      ? t("widgets:view-in-collection")
                                      : t("widgets:view-in-player")
                                  : hasCatalogClaim
                                  ? t("widgets:views-in-collection")
                                  : t("widgets:views-in-player")
                              : t("widgets:total-views")}
                      </span>
                  ]
                : []),
            ...(isUnpublished
                ? [
                      <span key="status" className={styles["status"]}>
                          {t("widgets:Unpublished")}
                      </span>
                  ]
                : []),
            ...(isScheduledUpload
                ? [
                      <>
                          {!dayOfBroadcast && (
                              <span
                                  key="premiering"
                                  className={styles["premiering"]}
                              >
                                  {t("widgets:Premiering")}
                                  <span className={styles["date"]}>
                                      {dayjs(startDate).format("MM/DD/YY")}
                                  </span>
                              </span>
                          )}
                          {dayOfBroadcast && (
                              <span
                                  key="premiering"
                                  className={styles["premiering"]}
                              >
                                  {t("widgets:Premiering-day")}
                                  <span className={styles["date"]}>
                                      {localizedTime}
                                  </span>
                              </span>
                          )}
                      </>
                  ]
                : []),
            ...(isScheduledLive
                ? [
                      <>
                          {!dayOfBroadcast && (
                              <span
                                  key="premiering"
                                  className={styles["premiering"]}
                              >
                                  {t("widgets:Scheduled-Live")}
                                  <span className={styles["date"]}>
                                      {dayjs(startDate).format("MM/DD/YY")}
                                  </span>
                              </span>
                          )}
                          {dayOfBroadcast && (
                              <span
                                  key="premiering"
                                  className={styles["premiering"]}
                              >
                                  {t("widgets:Scheduled-Live-Day")}
                                  <span className={styles["date"]}>
                                      {localizedTime}
                                  </span>
                              </span>
                          )}
                      </>
                  ]
                : [])
        ],
        [
            isScheduledUpload,
            isScheduledLive,
            startDate,
            video,
            broadcast?.InputId,
            t,
            metrics,
            location,
            hasCatalogClaim,
            isUnpublished,
            dayOfBroadcast,
            localizedTime
        ]
    );

    const isTooBigToDownload = useMemo<boolean>(() => {
        // Cannot download videos that are over 4 hours
        return video?.duration > 14400;
    }, [video?.duration]);

    const isDisabled = disabled || isVideoProcessing;
    const localDisabledVariant = isVideoProcessing
        ? DisabledVariant.Processing
        : disabledVariant;

    return (
        <>
            {!!broadcast && (
                <>
                    <div className={styles["broadcast-details"]}>
                        <BaseDetails
                            variant={variant}
                            isDraggable={isDraggable}
                            disabled={isDisabled}
                            disabledVariant={localDisabledVariant}
                            thumbnail={
                                <BroadcastThumbnail
                                    thumbnailImageURL={thumbnailImageURL}
                                    allowVideoPlaybackOnThumbnailClick={
                                        allowVideoPlaybackOnThumbnailClick
                                    }
                                    handlePlayVideo={() =>
                                        setVideoPlaybackModalIsOpen(true)
                                    }
                                    isScheduledUpload={isScheduledUpload}
                                    isScheduledLive={isScheduledLive}
                                    scheduledSecondsRemaining={
                                        timeRemaining ?? 0
                                    }
                                    disabled={isDisabled}
                                    disabledVariant={localDisabledVariant}
                                />
                            }
                            title={shorten(broadcast.Title, 60)}
                            detailRows={
                                <>
                                    {isDisabled &&
                                    localDisabledVariant ===
                                        DisabledVariant.Disabled ? (
                                        <p className={styles["disabled"]}>
                                            {t("broadcast-details:disabled")}
                                        </p>
                                    ) : (
                                        <>
                                            <DetailRow variant="info-row">
                                                <ComponentMap
                                                    items={
                                                        broadcastDetailsItems
                                                    }
                                                    element={(i) => i}
                                                    separator={
                                                        <span>{"•"}</span>
                                                    }
                                                />
                                            </DetailRow>
                                            <DetailRow variant="badge-row">
                                                {badges ? (
                                                    <VideoBadges
                                                        broadcast={broadcast}
                                                        playlistBroadcast={
                                                            playlistBroadcast as VideoPlayerPlaylistBroadcast
                                                        }
                                                        entitlements={
                                                            entitlements ??
                                                            (playlistBroadcast?.EntitlementProducts as CreatorProductEntitlement[])
                                                        }
                                                        players={players}
                                                        groupEntitlements={
                                                            location ===
                                                            "video-library"
                                                        }
                                                        shownEntitlements={[
                                                            "all"
                                                        ]}
                                                    />
                                                ) : null}
                                            </DetailRow>
                                        </>
                                    )}
                                </>
                            }
                            actionItems={
                                <>
                                    {showEdit && (
                                        <>
                                            {!mobileCheck.isMobile ? (
                                                <>
                                                    <Button
                                                        type="icon"
                                                        onClick={() =>
                                                            location ===
                                                            "video-library"
                                                                ? navigate(
                                                                      `/video-library/video/${broadcast.Id}`
                                                                  )
                                                                : navigate(
                                                                      `/catalog/collections/${collectionId}/video/${playlistBroadcast?.Id}`
                                                                  )
                                                        }
                                                        disabled={disabled}
                                                        title={t(
                                                            "buttons:edit"
                                                        )}
                                                    >
                                                        <PencilIcon
                                                            className={
                                                                styles[
                                                                    "btn-edit"
                                                                ]
                                                            }
                                                        />
                                                    </Button>
                                                    {location ===
                                                        "video-library" && (
                                                        <ContextMenuWrapper
                                                            items={shareOptions}
                                                            dropDirection="up"
                                                        >
                                                            <Button
                                                                type="icon"
                                                                disabled={
                                                                    disabled ||
                                                                    !shareable
                                                                }
                                                                title={t(
                                                                    "buttons:share"
                                                                )}
                                                            >
                                                                <ShareIcon />
                                                            </Button>
                                                        </ContextMenuWrapper>
                                                    )}
                                                    {showDownload && (
                                                        <Button
                                                            type="icon"
                                                            onClick={() =>
                                                                handleDownload({
                                                                    broadcastId:
                                                                        broadcast.Id,
                                                                    videoId:
                                                                        video?.uid,
                                                                    title: broadcast.Title
                                                                })
                                                            }
                                                            showProgress={
                                                                downloadStatus ===
                                                                VideoDownloadStatus.InProgress
                                                            }
                                                            progress={
                                                                percentComplete
                                                            }
                                                            disabled={
                                                                disabled ||
                                                                !isReadyForDownload ||
                                                                isTooBigToDownload
                                                            }
                                                            title={
                                                                isTooBigToDownload
                                                                    ? t(
                                                                          "video-library:too-big-to-download"
                                                                      )
                                                                    : t(
                                                                          "buttons:download"
                                                                      )
                                                            }
                                                        >
                                                            <DownloadIcon />
                                                        </Button>
                                                    )}

                                                    {location ===
                                                    "video-library" ? (
                                                        <Button
                                                            type="icon"
                                                            onClick={
                                                                handleDeleteBroadcast
                                                            }
                                                            title={t(
                                                                "buttons:delete"
                                                            )}
                                                        >
                                                            <TrashIcon
                                                                className={
                                                                    styles[
                                                                        "trash-icon"
                                                                    ]
                                                                }
                                                            />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            type="remove"
                                                            onClick={
                                                                handleDeleteBroadcast
                                                            }
                                                            title={t(
                                                                "buttons:remove"
                                                            )}
                                                        >
                                                            {t(
                                                                "video-player-settings:remove"
                                                            )}
                                                        </Button>
                                                    )}
                                                </>
                                            ) : (
                                                <ContextMenu
                                                    dropDirection="up"
                                                    items={
                                                        mobileButtonsMenuItems
                                                    }
                                                />
                                            )}
                                        </>
                                    )}

                                    <VideoPlaybackModal
                                        broadcastId={broadcast.Id}
                                        isOpen={videoPlaybackModalIsOpen}
                                        setIsOpen={setVideoPlaybackModalIsOpen}
                                    />

                                    {exists(gatedContentStatus) &&
                                        gatedContentStatus !==
                                            GatedContentStatus.FEATURE_DISABLED &&
                                        showPricing && (
                                            <div
                                                className={
                                                    styles[
                                                        "price-selection-container"
                                                    ]
                                                }
                                            >
                                                <GatedContentDisabledTooltip
                                                    gatedContentStatus={
                                                        gatedContentStatus
                                                    }
                                                >
                                                    <Button
                                                        disabled={
                                                            gatedContentStatus !==
                                                            GatedContentStatus.READY
                                                        }
                                                        type="badge"
                                                        isActive={exists(
                                                            productEntitlement
                                                        )}
                                                        onClick={() => {
                                                            setExistingEntitlement(
                                                                productEntitlement
                                                            );
                                                            setSelectedPlaylistBroadcastId(
                                                                playlistBroadcast?.Id
                                                            );
                                                            setPricingSelectModalIsOpen(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        {exists(
                                                            productEntitlement
                                                        )
                                                            ? productEntitlement
                                                                  ?.Product
                                                                  ?.Name
                                                            : t(
                                                                  "video-player-settings:add-pricing"
                                                              )}
                                                    </Button>
                                                </GatedContentDisabledTooltip>
                                            </div>
                                        )}
                                </>
                            }
                        ></BaseDetails>
                    </div>
                </>
            )}
        </>
    );
};
