/* Complete documentation for the useSwitcherClient hook can be found at:
    https://app.shortcut.com/switcher-studio/write/IkRvYyI6I3V1aWQgIjY3MzUyYjEyLTkyMDQtNGU1OC05YWM5LTBmNGI3OWIzM2M3NSI=
*/

import {
    useCallback,
    useEffect,
    useState,
    useContext,
    useRef,
    useMemo
} from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { setLoading as setStoreLoading } from "store/loading/slice";
import { Client } from "@switcherstudio/switcher-api-client";
import { SwitcherClientContext } from "App";
import { isEqual } from "lodash";
import { setApiResponse } from "store/api/slice";
import { SwitcherApiResponse } from "store/api/types";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { exists } from "helpers/booleans";
import { ClientCore } from "api/client-core";

/* Types */
type AsyncReturnType<T extends Promise<any>> = T extends Promise<infer R>
    ? R
    : any;

interface SwitcherClientCallbackRequestUpdateOptions {
    hideLoading?: boolean;
}

/**
 * Options for the useSwitcherClient hook
 * @param X - The arguments to pass to the clientMethod
 * @param U - The response type of the clientMethod
 * @param Y - The transformed response type
 */
interface SwitcherClientOptions<X, U, Y>
    extends SwitcherClientCallbackRequestUpdateOptions {
    /**
     * The arguments to pass to the clientMethod. If args change during component
     * lifecycle and "requestImmediately" is set to true a new API request will be triggered
     */
    args?: X;
    /**
     * Whether to initiate the request immediately upon component mount.
     * Optional - defaults to false.
     * Note: This does not prevent automatic re-fetching of data when args change
     */
    requestImmediately?: boolean;
    preFetch?: () => void;
    /**
     * @param data The response from the API request
     * @returns Void or Promise of void
     *
     * A callback function to call after the API request succeeds.
     * Can be synchronous or asynchronous
     */
    onSuccess?: (data: U, transformedData: Y) => Promise<void> | void;
    /**
     * @param e Error from API request response
     * @returns Void
     *
     * A callback function to call when the API request fails.
     */
    onError?: (e: any, response?: U) => void;
    /** Delays termination of global loading animation
     *  until onSuccess returns (synchronously or asynchronously)
     */
    onSuccessLoading?: boolean;
    /**
     * Optional function that transforms the response data before passing it to onSuccess.
     * This transformation happens after the local loading is set to false, but before the loading state is set to false.
     * This data is exposed via the exported transformedData variable.
     */
    transformResponseData?: ({
        originalResponse,
        originalArgs
    }: {
        originalResponse: U;
        originalArgs: X;
    }) => Promise<Y> | Y;
    /**
     * The fetch policy to use when fetching data.
     * Optional enum - defaults to "network-only"
     *  - cache-first: Return from cache if available, otherwise make fresh request.
     *  - cache-and-network: Return from cache if available. Meanwhile, make new request (w/o loading animation) and update cache when resolved.
     *  - network-only: Ignore cache and make fresh request.
     */
    fetchPolicy?: "cache-first" | "network-only" | "cache-and-network";
}

export function useSwitcherClientCore<U, X extends any[], Y>(
    clientMethod: (client: ClientCore) => (...P: X) => Promise<U>,
    options?: SwitcherClientOptions<X, U, Y>
) {
    return useSwitcherClientInternal<ClientCore, U, X, Y>(
        clientMethod,
        options,
        true
    );
}

export function useSwitcherClient<U, X extends any[], Y>(
    clientMethod: (client: Client) => (...P: X) => Promise<U>,
    options?: SwitcherClientOptions<X, U, Y>
) {
    return useSwitcherClientInternal<Client, U, X, Y>(
        clientMethod,
        options,
        false
    );
}

/**
 * Internal hook for useSwitcherClient and useSwitcherClientCore
 */
function useSwitcherClientInternal<
    A extends Client | ClientCore,
    U,
    X extends any[],
    Y
>(
    /**
     * The Switcher Client method to be invoked.
     * Requires method definition (rather than the immediately invoked method)
     */
    clientMethod: (client: A) => (...P: X) => Promise<U>,
    options?: SwitcherClientOptions<X, U, Y>,
    useCore: boolean = false
) {
    /* Local Types */
    interface CancelablePromise {
        promise: Promise<U>;
        cancel: (...args: any) => any;
    }

    /* State */

    // Context
    const clientContext = useContext(SwitcherClientContext);
    const switcherClient = useCore
        ? clientContext.switcherClientCore
        : clientContext.switcherClient;

    // Local
    const [data, setData] = useState<AsyncReturnType<Promise<U>>>(null);
    const [transformedData, setTransformedData] = useState<Y>(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(
        options?.requestImmediately ?? false
    );
    const dispatch = useDispatch<AppDispatch>();
    const [isInitialRequest, setisInitialRequest] = useState(true);
    const cancelablePromise = useRef<CancelablePromise>(null);

    // References
    const argsRef = useRef({ clientMethod, options });
    const updatedArgsRef = useRef<X>();
    const updatedOptionsRef =
        useRef<SwitcherClientCallbackRequestUpdateOptions>();
    const fetchPolicy = useMemo(
        () => options?.fetchPolicy ?? "network-only",
        [options]
    );

    // Redux
    const apiState = useSelector((s: RootState) => s.api);

    /* Memoized values */
    const cachedApiResponse = useMemo(() => {
        const argsStr = updatedArgsRef.current
            ? JSON.stringify(updatedArgsRef.current)
            : JSON.stringify(argsRef.current?.options?.args);
        const clientMethodStr = `${clientMethod}`;
        return apiState[clientMethodStr]?.[argsStr]?.response;
    }, [apiState, clientMethod]);

    /* Memoized functions */
    const getNormalizedRequestKey = useCallback(() => {
        return `${argsRef.current?.clientMethod}____${JSON.stringify(
            updatedArgsRef.current ?? argsRef.current?.options?.args
        )}`;
    }, []);

    const dispatchLoading = useCallback(
        (payload: number) => {
            const hideLoading =
                updatedOptionsRef.current?.hideLoading ??
                argsRef.current.options?.hideLoading ??
                true;
            if (!hideLoading) {
                dispatch(setStoreLoading(payload));
            }
        },
        [dispatch]
    );

    const makeCancelable = useCallback((promise): CancelablePromise => {
        let _hasCanceled = false;

        const wrappedPromise = new Promise<U>((resolve, reject) => {
            promise.then(
                (val) => (_hasCanceled ? resolve(null) : resolve(val)),
                (error) =>
                    _hasCanceled ? reject({ isCanceled: true }) : reject(error)
            );
        });

        wrappedPromise.catch(() => {});

        return {
            promise: wrappedPromise,
            cancel() {
                _hasCanceled = true;
            }
        };
    }, []);

    const fetchAndStoreFreshResponse = useCallback(() => {
        argsRef.current
            ?.clientMethod(switcherClient as A)
            .apply(
                switcherClient,
                updatedArgsRef.current ?? argsRef.current.options?.args
            )
            .then((res) => {
                const normalizedKey = getNormalizedRequestKey();
                setApiResponse(new SwitcherApiResponse(normalizedKey, res));
            });
    }, [getNormalizedRequestKey, switcherClient]);

    const handleResponse = useCallback(
        async (response: U, updatedArgs?: X, fromCacheRefresh?: boolean) => {
            setData(response);
            const normalizedKey = getNormalizedRequestKey();

            // If the response is not from a cache refresh, store the response in the redux store
            if (!fromCacheRefresh) {
                dispatch(
                    setApiResponse(
                        new SwitcherApiResponse(normalizedKey, response)
                    )
                );

                // If handleResponse was called because cachedApiResponse changed (because the redux api
                // response updated from a "cache-and-network" async fetch) do not store the response in redux
            }

            let transformedRes: Y;
            if (!!argsRef.current?.options?.transformResponseData) {
                transformedRes = await Promise.resolve(
                    argsRef.current.options.transformResponseData({
                        originalResponse: response,
                        originalArgs:
                            updatedArgs ?? argsRef.current.options?.args
                    })
                );
                setTransformedData(transformedRes);
            }
            setLoading(false);
            setError(null);
            if (!!argsRef.current.options?.onSuccess) {
                if (argsRef.current.options.onSuccessLoading) {
                    Promise.resolve(
                        argsRef.current.options.onSuccess(
                            response,
                            transformedRes
                        )
                    ).finally(() => dispatchLoading(-1));
                } else {
                    argsRef.current.options.onSuccess(response, transformedRes);
                    dispatchLoading(-1);
                }
            } else {
                dispatchLoading(-1);
            }

            return response;
        },
        [dispatch, dispatchLoading, getNormalizedRequestKey]
    );

    const request = useCallback(
        async (
            updatedArgs?: X,
            updatedOptions?: SwitcherClientOptions<X, U, Y>
        ) => {
            updatedArgsRef.current = updatedArgs;
            updatedOptionsRef.current = updatedOptions;

            setLoading(true);

            !!argsRef.current.options?.preFetch &&
                argsRef.current.options?.preFetch();

            if (
                ["cache-first", "cache-and-network"].includes(fetchPolicy) &&
                cachedApiResponse
            ) {
                if (fetchPolicy === "cache-and-network") {
                    // fetches fresh response from server and updates cache silently while cached result is returned
                    fetchAndStoreFreshResponse();
                }

                // return resolved promise on handled response from cached response if it exists
                // otherwise, fall back to fetching the api response
                return Promise.resolve(handleResponse(cachedApiResponse));
            }

            dispatchLoading(1);

            try {
                cancelablePromise.current = makeCancelable(
                    argsRef.current
                        .clientMethod(switcherClient as A)
                        .apply(
                            switcherClient,
                            updatedArgs?.length
                                ? updatedArgs
                                : argsRef.current.options?.args ?? ([] as X)
                        )
                );
                cancelablePromise.current.promise
                    .then((res) => {
                        handleResponse(res, updatedArgs);
                    })
                    .catch((e) => {
                        if (!e.isCanceled) {
                            setError(e);
                            setLoading(false);
                            if (!!argsRef.current.options?.onError) {
                                if (!!e?.response) {
                                    try {
                                        const parsedResponse = JSON.parse(
                                            e.response
                                        ) as U;
                                        argsRef.current.options.onError(
                                            e,
                                            parsedResponse
                                        );
                                    } catch (parseError) {
                                        argsRef.current.options.onError(e);
                                    }
                                } else {
                                    argsRef.current.options.onError(e);
                                }
                            }
                        }
                        dispatchLoading(-1);
                    });

                return cancelablePromise.current.promise;
            } catch (e) {
                if (!e.isCanceled) {
                    setError(e);
                    setLoading(false);
                    if (!!argsRef.current.options?.onError) {
                        argsRef.current.options.onError(e);
                    }
                }
                dispatchLoading(-1);
            }
        },
        [
            fetchPolicy,
            dispatchLoading,
            cachedApiResponse,
            fetchAndStoreFreshResponse,
            handleResponse,
            makeCancelable,
            switcherClient
        ]
    );

    const dispatchApiRequest = useCallback(
        (
            updatedArgs?: X,
            updatedOptions?: SwitcherClientCallbackRequestUpdateOptions
        ) => {
            return request(updatedArgs, updatedOptions);
        },
        [request]
    );

    /* Effects */

    // update args on args dependency change and re-call request if requestImmediately is true
    useEffect(() => {
        if (
            !!options &&
            !isEqual(options?.args, argsRef?.current?.options?.args) &&
            argsRef.current.options?.requestImmediately
        ) {
            request(options.args);
        }

        argsRef.current.options = options;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    // cancel request on unmount
    useEffect(() => {
        return () => {
            cancelablePromise.current && cancelablePromise.current.cancel();
        };
    }, []);

    useEffect(() => {
        if (
            !!argsRef.current.clientMethod &&
            argsRef.current.options?.requestImmediately &&
            isInitialRequest
        ) {
            setisInitialRequest(false);
            request();
        }
    }, [isInitialRequest, request]);

    // rerun handleResponse when api response in redux updates async from "cache-and-network" fetch policy
    useEffect(() => {
        if (exists(cachedApiResponse) && fetchPolicy === "cache-and-network") {
            handleResponse(cachedApiResponse, undefined, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cachedApiResponse]);

    /* Return hook values */
    return {
        data,
        error,
        loading,
        dispatchApiRequest,
        transformedData
    };
}
