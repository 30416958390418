import React, { useState, useCallback, useMemo } from "react";
import commonStyles from "./CommonStyles.module.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AnalyticsIcon from "assets/icons/analytics.svg?react";
import { Select } from "components/inputs/select/Select";
import { Widget } from "./Widget";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import dayjs from "dayjs";
import {
    formatTimeDuration,
    getHoursFromTimespan,
    getUTCDate,
    setToStartOfDay
} from "helpers/time";
import { Spinner } from "components/spinners/Spinner";
import { exists } from "helpers/booleans";

export type IntervalFilterOptions = "7" | "30" | "ytd";

export const AnalyticsWidget: React.FC = () => {
    const { t } = useTranslation();
    const [selectedFilter, setSelectedFilter] =
        useState<IntervalFilterOptions>("7");

    const startDate = useMemo(() => {
        switch (selectedFilter) {
            case "7":
                return dayjs(setToStartOfDay(getUTCDate()))
                    .subtract(7, "day")
                    .toISOString();
            case "30":
                return dayjs(setToStartOfDay(getUTCDate()))
                    .subtract(30, "day")
                    .toISOString();
            case "ytd":
                return dayjs(setToStartOfDay(getUTCDate()))
                    .startOf("year")
                    .toISOString();
            default:
                return dayjs(setToStartOfDay(getUTCDate()))
                    .subtract(7, "day")
                    .toISOString();
        }
    }, [selectedFilter]);

    const {
        data: userMetrics,
        dispatchApiRequest: refetchMetrics,
        loading: metricsLoading
    } = useSwitcherClient((client) => client.videoAnalytics_GetMetrics, {
        requestImmediately: true,
        args: [{ StartDate: startDate }],
        hideLoading: true
    });

    const dropdownOptions = [
        { value: "7", text: t("widgets:last-seven") },
        { value: "30", text: t("widgets:last-thirty") },
        { value: "ytd", text: t("widgets:year-to-date") }
    ];

    const onDropdownChange = useCallback(
        (dateRange) => {
            setSelectedFilter(dateRange);
            refetchMetrics(dateRange);
        },
        [refetchMetrics]
    );

    const filteredData = useMemo(
        () => [
            {
                label: t("widgets:views"),
                value: userMetrics?.TotalViews ?? "0"
            },
            {
                label: t("widgets:watch-time"),
                value: exists(userMetrics?.TotalWatchedSeconds)
                    ? formatTimeDuration(userMetrics?.TotalWatchedSeconds)
                    : "0"
            },
            {
                label: t("widgets:unique-viewers"),
                value: userMetrics?.TotalViewers ?? "0"
            }
        ],
        [userMetrics, t]
    );
    const lastUpdatedHours = useMemo(() => {
        if (metricsLoading) return null;
        return getHoursFromTimespan(userMetrics?.Age);
    }, [userMetrics?.Age, metricsLoading]);

    return (
        <Widget
            icon={<AnalyticsIcon />}
            title={t("widgets:analytics-widget-title").toUpperCase()}
            details={[
                {
                    children: (
                        <div className={commonStyles["analytics-content"]}>
                            <Select
                                id="analytics-select"
                                options={dropdownOptions?.map((o) => {
                                    return {
                                        value: o.value,
                                        text: o.text
                                    };
                                })}
                                selected={selectedFilter}
                                onChange={(e) =>
                                    onDropdownChange(e.target.value)
                                }
                            />
                            {!metricsLoading ? (
                                filteredData?.map((data) => (
                                    <span key={data?.label}>
                                        <p>{data?.label}</p>
                                        <strong>{data?.value}</strong>
                                    </span>
                                ))
                            ) : (
                                <span className={commonStyles["spinner"]}>
                                    <Spinner />
                                </span>
                            )}
                        </div>
                    )
                }
            ]}
            footerSubtitle={
                !metricsLoading
                    ? `Last updated ${
                          lastUpdatedHours < 1
                              ? "less than an hour"
                              : `${lastUpdatedHours} hours`
                      } ago.`
                    : null
            }
            button={
                <Link className="btn btn-outline-secondary" to={`/analytics`}>
                    {t("widgets:more-details")}
                </Link>
            }
        ></Widget>
    );
};
