import {
    Link as RouterLink,
    LinkProps as RouterLinkProps
} from "react-router-dom";
import styles from "./index.module.scss";

export interface LinkProps extends RouterLinkProps {
    variant?: "link" | "underline";
}

/**
 * A styled wrapper for react-router-dom <Link> component
 */
export const Link = (props: LinkProps) => (
    <RouterLink {...props} className={styles[props.variant || "link"]}>
        {props.children}
    </RouterLink>
);
