import PassEmptyState from "components/empty-state/PassEmptyState";
import { Spinner } from "components/spinners/Spinner";
import { GatedContentStatus } from "hooks/useUserStripeData";
import { t } from "i18next";
import { PropsWithChildren } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";

export interface StripeConnectWrapperProps extends PropsWithChildren {
    loading: boolean;
    gatedContentStatus: GatedContentStatus;
    href: string;
    showLinkToManageGatedContentPasses?: boolean;
    spinnerSize?: number;
}

/**
 * A wrapper for common UI that handles loading, gated content status, and account connection for Stripe Connect accounts in
 * various scenarios that a user might be in when trying to access gated content. It provides a consistent interface for
 * managing user access and content visibility.
 *
 * Scenario 1: Loading - When the user is waiting for the Stripe account to load.
 * Scenario 2: Not Ready - When the user has not connected their Stripe account. Shows a link to connect the account.
 * Scenario 3: No Passes - The user has not yet created passes. Shows a link to create passes.
 * Scenario 4: Ready - When the user has connected their Stripe account. Will render whatever children are passed to it.
 */
export const StripeConnectWrapper = ({
    children,
    loading,
    gatedContentStatus,
    href,
    showLinkToManageGatedContentPasses,
    spinnerSize = 44
}: StripeConnectWrapperProps) => {
    if (loading) return <Spinner size={spinnerSize} />;

    if (gatedContentStatus !== GatedContentStatus.READY)
        return (
            <PassEmptyState
                message={t(
                    "players:gated-content:connect-stripe-account-description"
                )}
                linkText={t(
                    "players:gated-content:connect-stripe-account-link-text"
                )}
                linkHref={href}
            ></PassEmptyState>
        );

    if (showLinkToManageGatedContentPasses)
        return (
            <Trans
                i18nKey={t("gated-content-page:video-create-pass")}
                components={{
                    link1: (
                        <Link
                            to={"/gated-content#passes"}
                            title="Manage Gated Content Passes"
                            className={styles["link"]}
                        />
                    )
                }}
            />
        );

    return <>{children}</>;
};
