import { useSwitcherClient } from "hooks/useSwitcherClient";
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState
} from "react";
import { ModalBase } from "../ModalBase";
import styles from "./index.module.scss";
import CloseIcon from "assets/icons/close.svg?react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import rollbar from "helpers/rollbar";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { exists } from "helpers/booleans";
import {
    PlayerResponse,
    VideoPlayer,
    VideoPlayerAspectRatio,
    VideoPlayersResponse
} from "@switcherstudio/switcher-api-client";
import { postEvents } from "store/events/thunks";
import { AppDispatch } from "store/store";
import { useClaimCheck } from "hooks/useClaimCheck";
import { ThumbnailUploader } from "components/video-upload/components/thumbnail-uploader";
import { AspectRatio } from "views/page-content/cloud/upload/types";
import { useThumbnailUpload } from "hooks/useThumbnailUpload";
import { TextInput } from "components/inputs/text-input/TextInput";
import { isNullOrWhitespace } from "helpers/strings";

export interface CreateOrUpdateSwitcherPlayerModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    type: "create" | "update";
    onSubmit: (vpr?: VideoPlayersResponse) => void;
    videoPlayer?: PlayerResponse;
}

export const CreateOrUpdateSwitcherPlayerModal = ({
    isOpen,
    setIsOpen,
    type,
    onSubmit,
    videoPlayer
}: CreateOrUpdateSwitcherPlayerModalProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const [name, setName] = useState<string>();
    const [aspectRatio, setAspectRatio] = useState<VideoPlayerAspectRatio>();
    const [description, setDescription] = useState<string>();
    const userState = useSelector((s: RootState) => s.user);
    const enableCatalog = useClaimCheck("catalog");
    const { t } = useTranslation();

    const notifyUserAfterSubmit = useCallback(
        (
            operation: "create" | "update",
            notificationType: NotificationType
        ) => {
            const messages = {
                createSuccess: enableCatalog
                    ? t("collections-page:collection-create-success")
                    : t("players:messages:player-create-success"),
                createError: enableCatalog
                    ? t("collections-page:collection-create-error")
                    : t("players:errors:player-create-error"),
                updateSuccess: enableCatalog
                    ? t("collections-page:collection-update-success")
                    : t("players:messages:player-update-success"),
                updateError: enableCatalog
                    ? t("collections-page:collection-update-error")
                    : t("players:errors:player-update-error")
            };

            const messageKey = `${operation}${
                notificationType === NotificationType.Success
                    ? "Success"
                    : "Error"
            }`;

            dispatch(
                addNotification({
                    type: notificationType,
                    message: messages[messageKey]
                })
            );
        },
        [t, dispatch, enableCatalog]
    );

    /** reset the name/description in modal if the user closes without saving changes */
    useEffect(() => {
        if (!isOpen && !!videoPlayer) {
            setName(videoPlayer?.Details?.Name);
            setDescription(videoPlayer?.Details?.Description ?? "");
            // takes care of typing differences
            setAspectRatio(
                (videoPlayer?.Details
                    ?.AspectRatio as any as VideoPlayerAspectRatio) ??
                    VideoPlayerAspectRatio.SixteenByNine
            );
        }
    }, [isOpen, videoPlayer]);

    const { data: currentVideoPlayerProfile } = useSwitcherClient(
        (client) =>
            client.videoPlayerProfile_GetVideoPlayerProfileByVideoPlayerId,
        {
            requestImmediately: exists(videoPlayer?.Details?.Id),
            args: [videoPlayer?.Details?.Id]
        }
    );

    const { loading: postLoading, dispatchApiRequest: postVideoPlayers } =
        useSwitcherClient(
            (client) => client.projectsVideoPlayer_PostVideoPlayers,
            {
                onSuccess: (response: VideoPlayersResponse) => {
                    notifyUserAfterSubmit("create", NotificationType.Success);
                    dispatch(postEvents({ "created-player": true }));
                    onSubmit && onSubmit(response);
                    setIsOpen(false);
                },
                onError: (e) => {
                    notifyUserAfterSubmit("create", NotificationType.Danger);
                    rollbar.error("Error creating video players", e);
                    setIsOpen(false);
                }
            }
        );

    const { loading: putLoading, dispatchApiRequest: updateVideoPlayer } =
        useSwitcherClient(
            (client) => client.projectsVideoPlayer_PutVideoPlayer,
            {
                onSuccess: () => {
                    notifyUserAfterSubmit("update", NotificationType.Success);
                    onSubmit && onSubmit();
                    setIsOpen(false);
                },
                onError: (e) => {
                    notifyUserAfterSubmit("update", NotificationType.Danger);
                    rollbar.error("Error updating video players", e);
                    setIsOpen(false);
                }
            }
        );

    const [thumbnailFile, setThumbnailFile] = useState<File>();
    const [thumbnailRemoved, setThumbnailRemoved] = useState<boolean>(false);
    const [thumbnailImageURL, setThumbnailImageURL] = useState<string>(
        !!videoPlayer?.Details?.ThumbnailAssetId
            ? videoPlayer?.Thumbnail?.Url
            : ""
    );

    const {
        handleUploadThumbnail,
        handleUpdateThumbnail,
        handleDeleteThumbnail
    } = useThumbnailUpload(thumbnailFile, thumbnailImageURL);

    const makeVideoPlayerApiObject = useCallback(
        async (videoPlayer: PlayerResponse) => {
            let apiObject: VideoPlayer | undefined;

            if (type === "create") {
                apiObject = {
                    ProjectId: userState?.userInfo?.ProjectId,
                    Name: name,
                    Description: description,
                    VideoPlayerPlaylists: [
                        {
                            Title: "Default Playlist",
                            IsDefault: true,
                            VideoPlayerId: null
                        }
                    ],
                    AspectRatio: aspectRatio
                };
            } else {
                apiObject = {
                    ...currentVideoPlayerProfile?.VideoPlayer,
                    Name: name,
                    Description: exists(description) ? description : null
                };
            }

            // Update the thumbnail if it has changed
            if (thumbnailFile) {
                const thumbnail =
                    type === "create"
                        ? await handleUploadThumbnail()
                        : await handleUpdateThumbnail(videoPlayer?.Details);
                apiObject.ThumbnailAssetId = thumbnail.Id;
            } else if (thumbnailRemoved) {
                handleDeleteThumbnail(videoPlayer?.Details?.ThumbnailAssetId);
                apiObject.ThumbnailAssetId = undefined;
            }

            return apiObject;
        },
        [
            userState,
            name,
            description,
            aspectRatio,
            currentVideoPlayerProfile,
            type,
            thumbnailFile,
            handleUpdateThumbnail,
            thumbnailRemoved,
            handleDeleteThumbnail,
            handleUploadThumbnail
        ]
    );

    const [nameError, setNameError] = useState<string>();
    const handleSubmit = useCallback(async () => {
        // Validate name
        if (!name?.length || isNullOrWhitespace(name)) {
            setNameError(
                enableCatalog
                    ? t("collections:errors:name-length")
                    : t("players:errors:name-length")
            );
            return;
        }
        setNameError(undefined);

        // Make video player object for API call
        const newVideoPlayer = await makeVideoPlayerApiObject(videoPlayer);

        // Create (POST) or Update (PUT) video player
        if (type === "create") {
            postVideoPlayers([
                { VideoPlayers: [newVideoPlayer] },
                userState?.userInfo?.ProjectId
            ]);
        } else {
            updateVideoPlayer([
                videoPlayer?.Details?.Id,
                { VideoPlayer: newVideoPlayer },
                userState?.userInfo?.ProjectId
            ]);
        }
    }, [
        name,
        makeVideoPlayerApiObject,
        postVideoPlayers,
        userState,
        enableCatalog,
        t,
        updateVideoPlayer,
        videoPlayer,
        type
    ]);

    return (
        <ModalBase
            preventDismiss={postLoading || putLoading}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            stylesOverride={{
                padding: "0",
                height: "auto",
                maxWidth: "700px"
            }}
        >
            <>
                <div className={styles["header"]}>
                    {type === "create"
                        ? enableCatalog
                            ? t("collections-page:create-collection")
                            : t("players:create-player")
                        : t("buttons:edit-details")}

                    <button
                        className={styles["close-btn"]}
                        onClick={() => setIsOpen(false)}
                    >
                        <CloseIcon />
                    </button>
                </div>

                <div className={styles["content"]}>
                    <div className={styles["label-row"]}>
                        <label htmlFor="name">{t("switcher-cloud:name")}</label>

                        <small>{`${name?.length ?? 0} / 50`}</small>
                    </div>
                    <TextInput
                        id="name"
                        type="text"
                        value={name}
                        maxLength={50}
                        onChange={(e) => {
                            setName(e?.currentTarget?.value);
                        }}
                        error={nameError}
                    />
                    <div className={styles["label-row"]}>
                        <label htmlFor="description">
                            {t("video-upload:description")}
                        </label>

                        <small>{`${description?.length ?? 0} / 500`}</small>
                    </div>
                    <textarea
                        id="description"
                        className={`${styles["description-input"]} form-control`}
                        value={description}
                        maxLength={500}
                        onChange={(e) =>
                            setDescription(e?.currentTarget?.value)
                        }
                    />
                    <div className={styles["label-row"]}>
                        <label htmlFor="thumbnail-upload">
                            Share Thumbnail
                        </label>
                    </div>
                    <ThumbnailUploader
                        setThumbnailFile={setThumbnailFile}
                        thumbnailImageURL={thumbnailImageURL}
                        setThumbnailImageURL={setThumbnailImageURL}
                        onRemove={() => setThumbnailRemoved(true)}
                        orientation={AspectRatio.horizontal}
                        additionalMessage={t(
                            "inputs:thumbnail-upload:collection-sharing-thumbnail"
                        )}
                    />
                </div>

                <div className={styles["footer"]}>
                    <button
                        className="btn btn-text"
                        onClick={() => setIsOpen(false)}
                    >
                        {t("buttons:cancel")}
                    </button>

                    <button className="btn btn-primary" onClick={handleSubmit}>
                        {type === "create"
                            ? t("buttons:create")
                            : t("buttons:save")}
                    </button>
                </div>
            </>
        </ModalBase>
    );
};
