import React, { useEffect, useState, useCallback } from "react";
import { useSpeedTest } from "hooks/useSpeedTest";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import { SwitcherStreamSetting } from "@switcherstudio/switcher-api-client";
import { client } from "api/client";
import { SomTestStatus } from "../platforms/types";
import { useSwitcherSdk } from "hooks/useSwitcherSdk";
import { useNavigate } from "react-router-dom";
import { addNotification } from "store/notification/slice";
import { AppDispatch } from "store/store";
import { NotificationType } from "store/notification/types";
import { useTranslation } from "react-i18next";
import { useParams } from "hooks/useParams";

export const SpeedTestPage: React.FC = () => {
    const { channelSettingsId } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { userInfo } = useSelector((s: RootState) => s.user);
    const featureClaims = userInfo?.FeatureClaims;
    const navigate = useNavigate();

    const { isInBrowser } = useSwitcherSdk();
    const { startSomSpeedTest, somData, somTestStatus } = useSpeedTest();
    const [channel, setChannel] = useState<SwitcherStreamSetting>({});

    useEffect(() => {
        // Get channel settings if id is provided, and start the speed test
        async function run() {
            if (channelSettingsId) {
                try {
                    const channel =
                        await client.switcherStreamSettings_GetSwitcherStreamSetting(
                            channelSettingsId
                        );
                    setChannel(channel);
                    startSomSpeedTest();
                } catch {
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: t("platforms:channel-settings-error")
                        })
                    );
                }
            } else {
                startSomSpeedTest();
            }
        }

        run();
    }, [dispatch, channelSettingsId, startSomSpeedTest, t]);

    useEffect(() => {
        // Patch settings based on speed test results
        function createPatch(
            width: number,
            height: number,
            videoBitRate: number,
            audioChannelCount: number,
            audioBitrate: number,
            audioSampleRate: number
        ) {
            return {
                "video-frame-width": width,
                "video-frame-height": height,
                "video-bit-rate": videoBitRate,
                "audio-channel-count": audioChannelCount,
                "audio-bit-rate": audioBitrate,
                "audio-sample-rate": audioSampleRate
            };
        }

        if (somData.results) {
            const { upload } = somData.results;
            let update = {};

            /**
             * The upload settings are pulled from the recommendation from Cloudflare: https://developers.cloudflare.com/stream/faq/#what-are-the-recommended-upload-settings-for-video-uploads
             */
            if (upload >= 8) {
                update = createPatch(1920, 1080, 6000000, 2, 128000, 48000);
            } else if (upload >= 4.8) {
                update = createPatch(1280, 720, 3200000, 2, 128000, 48000);
            } else if (upload >= 2.4) {
                update = createPatch(960, 540, 1200000, 2, 128000, 48000);
            } else if (upload >= 1) {
                update = createPatch(640, 360, 800000, 1, 64000, 48000);
            } else {
                update = createPatch(480, 270, 500000, 1, 64000, 48000);
            }

            // set results
            setChannel((prev) => ({ ...prev, ...update }));
        }
    }, [somData.results, featureClaims]);

    const save = useCallback(async () => {
        if (!isInBrowser) {
            window.webkit.messageHandlers.switcher.postMessage({
                action: "setProfile",
                profile: channel
            });
        } else {
            try {
                await client.switcherStreamSettings_PutSwitcherStreamSetting(
                    channel["switcher-stream-setting-id"],
                    channel
                );
                navigate("/platforms");
            } catch {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message:
                            "There was a problem updating your channel settings."
                    })
                );
            }
        }
    }, [channel, dispatch, isInBrowser, navigate]);

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    <div className="alert alert-info text-center" role="alert">
                        {(somTestStatus === SomTestStatus.Waiting ||
                            (somTestStatus === SomTestStatus.InProgress &&
                                !somData?.progress?.type)) && (
                            <>Starting speed test...</>
                        )}
                        {somTestStatus === SomTestStatus.InProgress &&
                            somData?.progress?.type && (
                                <>
                                    Testing {somData?.progress?.type}: <br />
                                    {somData.progress.percentDone} %
                                </>
                            )}
                        {somTestStatus === SomTestStatus.Completed &&
                            somData?.results && (
                                <>
                                    Speed test complete! <br />
                                    <strong>
                                        Upload: {somData.results.upload} Mbps
                                    </strong>
                                    <br />
                                    <small>
                                        Download: {somData.results.download}{" "}
                                        Mbps
                                    </small>
                                </>
                            )}
                    </div>
                    {somTestStatus === SomTestStatus.Error &&
                        somData?.error && (
                            <div
                                className="alert alert-danger text-center"
                                role="alert"
                            >
                                {somData.error.message}
                            </div>
                        )}
                    {(!isInBrowser || channelSettingsId) && (
                        <button
                            className="btn btn-primary btn-block"
                            onClick={save}
                            disabled={somTestStatus !== SomTestStatus.Completed}
                        >
                            Save
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};
